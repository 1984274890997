import { Path } from 'slate';
import { useSlateStatic } from 'slate-react';
import {
  getPath,
  toggleOff as toggleOffHelper,
  toggleOn as toggleOnHelper
} from '../../../helpers';
import { LayersTypelessNode } from '../../types';

/**
 * Toggle property of an element through callbacks.
 *
 * @param node The node to toggle a property of
 * @param propertyToToggle The property to toggle
 * @returns Callbacks `toggleOn` and `toggleOff` for the property
 */
export default function useElementAttributeToggle(
  node: LayersTypelessNode,
  propertyToToggle: string,
  path?: Path
) {
  const editor = useSlateStatic();

  if (!path) {
    path = getPath(editor, node as any) as Path;
  }

  //wrap toggle operation in withoutSaving since there are unecessary undo/redo purposes
  const toggleOn = () => toggleOnHelper(editor, path as any, propertyToToggle);

  const toggleOff = () =>
    toggleOffHelper(editor, path as any, propertyToToggle);

  return {
    toggleOn,
    toggleOff
  };
}
