// import { componentConfigs } from '../../components';
import defaultNormalizer from './default-normalizer';

import { LayersEditor } from '../types';

/**
 * Apply component normalizations.
 *
 * Uses an array of functions to apply to the editor, as follows:
 * withNormalizedComponent(withNormalizedComponent(...(editor)))
 */
// export default function withNormalizedComponents(editor: LayersEditor) {
//   return componentConfigs.reduce(
//     (acc, withNormalizedComponent) => withNormalizedComponent(acc),
//     editor
//   );
// }

/**
 * Apply component normalizations.
 */
export default function withNormalizedComponents(editor: LayersEditor) {
  return defaultNormalizer(editor);
}
