const entities = [
  ['“', '&quot;'],
  ['&', '&amp;'],
  ['<', '&lt;'],
  ['>', '&gt;'],
  ['¡', '&iexcl;'],
  ['¢', '&cent;'],
  ['£', '&pound;'],
  ['¤', '&curren;'],
  ['¥', '&yen;'],
  ['¦', '&brvbar;'],
  ['§', '&sect;'],
  ['¨', '&uml;'],
  ['©', '&copy;'],
  ['ª', '&ordf;'],
  ['«', '&laquo;'],
  ['¬', '&not;'],
  ['®', '&reg;'],
  ['¯', '&macr;'],
  ['°', '&deg;'],
  ['±', '&plusmn;'],
  ['²', '&sup2;'],
  ['³', '&sup3;'],
  ['´', '&acute;'],
  ['µ', '&micro;'],
  ['¶', '&para;'],
  ['·', '&dot;'],
  ['¸', '&cedil;'],
  ['¹', '&sup1;'],
  ['º', '&ordm;'],
  ['»', '&raquo;'],
  ['¼', '&frac14;'],
  ['½', '&frac12;'],
  ['¾', '&frac34;'],
  ['¿', '&iquest;'],
  ['À', '&Agrave;'],
  ['Á', '&Aacute;'],
  ['Â', '&Acirc;'],
  ['Ã', '&Atilde;'],
  ['Ä', '&Auml;'],
  ['Å', '&Aring;'],
  ['Æ', '&AElig;'],
  ['Ç', '&Ccedil;'],
  ['È', '&Egrave;'],
  ['É', '&Eacute;'],
  ['Ê', '&Ecirc;'],
  ['Ë', '&Euml;'],
  ['Ì', '&Igrave;'],
  ['Í', '&Iacute;'],
  ['Î', '&Icirc;'],
  ['Ï', '&Iuml;'],
  ['Ð', '&ETH;'],
  ['Ñ', '&Ntilde;'],
  ['Ò', '&Ograve;'],
  ['Ó', '&Oacute;'],
  ['Ô', '&Ocirc;'],
  ['Õ', '&Otilde;'],
  ['Ö', '&Ouml;'],
  ['×', '&times;'],
  ['Ø', '&Oslash;'],
  ['Ù', '&Ugrave;'],
  ['Ú', '&Uacute;'],
  ['Û', '&Ucirc;'],
  ['Ü', '&Uuml;'],
  ['Ý', '&Yacute;'],
  ['Þ', '&THORN;'],
  ['ß', '&szlig;'],
  ['à', '&agrave;'],
  ['á', '&aacute;'],
  ['â', '&acirc;'],
  ['ã', '&atilde;'],
  ['ä', '&auml;'],
  ['å', '&aring;'],
  ['æ', '&aelig;'],
  ['ç', '&ccedil;'],
  ['è', '&egrave;'],
  ['é', '&eacute;'],
  ['ê', '&ecirc;'],
  ['ë', '&euml;'],
  ['ì', '&igrave;'],
  ['í', '&iacute;'],
  ['î', '&icirc;'],
  ['ï', '&iuml;'],
  ['ð', '&eth;'],
  ['ñ', '&ntilde;'],
  ['ò', '&ograve;'],
  ['ó', '&oacute;'],
  ['ô', '&ocirc;'],
  ['õ', '&otilde;'],
  ['ö', '&ouml;'],
  ['÷', '&divide;'],
  ['ø', '&oslash;'],
  ['ù', '&ugrave;'],
  ['ú', '&uacute;'],
  ['û', '&ucirc;'],
  ['ü', '&uuml;'],
  ['ý', '&yacute;'],
  ['þ', '&thorn;'],
  ['ÿ', '&yuml;'],
  ['Ā', '&Amacr;'],
  ['ā', '&amacr;'],
  ['Ă', '&Abreve;'],
  ['ă', '&abreve;'],
  ['Ą', '&Aogon;'],
  ['ą', '&aogon;'],
  ['Ć', '&Cacute;'],
  ['ć', '&cacute;'],
  ['Ĉ', '&Ccirc;'],
  ['ĉ', '&ccirc;'],
  ['Ċ', '&Cdot;'],
  ['ċ', '&cdot;'],
  ['Č', '&Ccaron;'],
  ['č', '&ccaron;'],
  ['Ď', '&Dcaron;'],
  ['ď', '&dcaron;'],
  ['Đ', '&Dstrok;'],
  ['đ', '&dstrok;'],
  ['Ē', '&Emacr;'],
  ['ē', '&emacr;'],
  ['Ĕ', '&Ebreve;'],
  ['ĕ', '&ebreve;'],
  ['Ė', '&Edot;'],
  ['ė', '&edot;'],
  ['Ę', '&Eogon;'],
  ['ę', '&eogon;'],
  ['Ě', '&Ecaron;'],
  ['ě', '&ecaron;'],
  ['Ĝ', '&Gcirc;'],
  ['ĝ', '&gcirc;'],
  ['Ğ', '&Gbreve;'],
  ['ğ', '&gbreve;'],
  ['Ġ', '&Gdot;'],
  ['ġ', '&gdot;'],
  ['Ģ', '&Gcedil;'],
  ['ģ', '&gcedil;'],
  ['Ĥ', '&Hcirc;'],
  ['ĥ', '&hcirc;'],
  ['Ħ', '&Hstrok;'],
  ['ħ', '&hstrok;'],
  ['Ĩ', '&Itilde;'],
  ['ĩ', '&itilde;'],
  ['Ī', '&Imacr;'],
  ['ī', '&imacr;'],
  ['Ĭ', '&Ibreve;'],
  ['ĭ', '&ibreve;'],
  ['Į', '&Iogon;'],
  ['į', '&iogon;'],
  ['İ', '&Idot;'],
  ['ı', '&inodot;'],
  ['Ĳ', '&IJlig;'],
  ['ĳ', '&ijlig;'],
  ['Ĵ', '&Jcirc;'],
  ['ĵ', '&jcirc;'],
  ['Ķ', '&Kcedil;'],
  ['ķ', '&kcedil;'],
  ['ĸ', '&kgreen;'],
  ['Ĺ', '&Lacute;'],
  ['ĺ', '&lacute;'],
  ['Ļ', '&Lcedil;'],
  ['ļ', '&lcedil;'],
  ['Ľ', '&Lcaron;'],
  ['ľ', '&lcaron;'],
  ['Ŀ', '&Lmidot;'],
  ['ŀ', '&lmidot;'],
  ['Ł', '&Lstrok;'],
  ['ł', '&lstrok;'],
  ['Ń', '&Nacute;'],
  ['ń', '&nacute;'],
  ['Ņ', '&Ncedil;'],
  ['ņ', '&ncedil;'],
  ['Ň', '&Ncaron;'],
  ['ň', '&ncaron;'],
  ['ŉ', '&napos;'],
  ['Ŋ', '&ENG;'],
  ['ŋ', '&eng;'],
  ['Ō', '&Omacr;'],
  ['ō', '&omacr;'],
  ['Ŏ', '&Obreve;'],
  ['ŏ', '&obreve;'],
  ['Ő', '&Odblac;'],
  ['ő', '&odblac;'],
  ['Œ', '&OElig;'],
  ['œ', '&oelig;'],
  ['Ŕ', '&Racute;'],
  ['ŕ', '&racute;'],
  ['Ŗ', '&Rcedil;'],
  ['ŗ', '&rcedil;'],
  ['Ř', '&Rcaron;'],
  ['ř', '&rcaron;'],
  ['Ś', '&Sacute;'],
  ['ś', '&sacute;'],
  ['Ŝ', '&Scirc;'],
  ['ŝ', '&scirc;'],
  ['Ş', '&Scedil;'],
  ['ş', '&scedil;'],
  ['Š', '&Scaron;'],
  ['š', '&scaron;'],
  ['Ţ', '&Tcedil;'],
  ['ţ', '&tcedil;'],
  ['Ť', '&Tcaron;'],
  ['ť', '&tcaron;'],
  ['Ŧ', '&Tstrok;'],
  ['ŧ', '&tstrok;'],
  ['Ũ', '&Utilde;'],
  ['ũ', '&utilde;'],
  ['Ū', '&Umacr;'],
  ['ū', '&umacr;'],
  ['Ŭ', '&Ubreve;'],
  ['ŭ', '&ubreve;'],
  ['Ů', '&Uring;'],
  ['ů', '&uring;'],
  ['Ű', '&Udblac;'],
  ['ű', '&udblac;'],
  ['Ų', '&Uogon;'],
  ['ų', '&uogon;'],
  ['Ŵ', '&Wcirc;'],
  ['ŵ', '&wcirc;'],
  ['Ŷ', '&Ycirc;'],
  ['ŷ', '&ycirc;'],
  ['Ÿ', '&Yuml;'],
  ['ƒ', '&fnof;'],
  ['ˆ', '&circ;'],
  ['˜', '&tilde;'],
  ['Α', '&Alpha;'],
  ['Β', '&Beta;'],
  ['Γ', '&Gamma;'],
  ['Δ', '&Delta;'],
  ['Ε', '&Epsilon;'],
  ['Ζ', '&Zeta;'],
  ['Η', '&Eta;'],
  ['Θ', '&Theta;'],
  ['Ι', '&Iota;'],
  ['Κ', '&Kappa;'],
  ['Λ', '&Lambda;'],
  ['Μ', '&Mu;'],
  ['Ν', '&Nu;'],
  ['Ξ', '&Xi;'],
  ['Ο', '&Omicron;'],
  ['Π', '&Pi;'],
  ['Ρ', '&Rho;'],
  ['Σ', '&Sigma;'],
  ['Τ', '&Tau;'],
  ['Υ', '&Upsilon;'],
  ['Φ', '&Phi;'],
  ['Χ', '&Chi;'],
  ['Ψ', '&Psi;'],
  ['Ω', '&Omega;'],
  ['α', '&alpha;'],
  ['β', '&beta;'],
  ['γ', '&gamma;'],
  ['δ', '&delta;'],
  ['ε', '&epsilon;'],
  ['ζ', '&zeta;'],
  ['η', '&eta;'],
  ['θ', '&theta;'],
  ['ι', '&iota;'],
  ['κ', '&kappa;'],
  ['λ', '&lambda;'],
  ['μ', '&mu;'],
  ['ν', '&nu;'],
  ['ξ', '&xi;'],
  ['ο', '&omicron;'],
  ['π', '&pi;'],
  ['ρ', '&rho;'],
  ['ς', '&sigmaf;'],
  ['σ', '&sigma;'],
  ['τ', '&tau;'],
  ['υ', '&upsilon;'],
  ['φ', '&phi;'],
  ['χ', '&chi;'],
  ['ψ', '&psi;'],
  ['ω', '&omega;'],
  ['ϑ', '&thetasym;'],
  ['ϒ', '&upsih;'],
  ['ϖ', '&piv;'],
  // En space
  ['\u2002', '&ensp;'],
  // Em space
  ['\u2003', '&emsp;'],
  // Thin space
  ['\u2009', '&thinsp;'],
  // Zero width non-joiner
  ['\u200C', '&zwnj;'],
  // Zero width joiner
  ['\u200D', '&zwj;'],
  // Left-to-right mark
  ['\u200E', '&lrm;'],
  // Right-to-left mark
  ['\u200F', '&rlm;'],
  ['–', '&ndash;'],
  ['—', '&mdash;'],
  ['‘', '&lsquo;'],
  ['’', '&rsquo;'],
  ['‚', '&sbquo;'],
  ['“', '&ldquo;'],
  ['”', '&rdquo;'],
  ['„', '&bdquo;'],
  ['†', '&dagger;'],
  ['‡', '&Dagger;'],
  ['•', '&bull;'],
  ['…', '&hellip;'],
  ['‰', '&permil;'],
  ['′', '&prime;'],
  ['″', '&Prime;'],
  ['‹', '&lsaquo;'],
  ['›', '&rsaquo;'],
  ['‾', '&oline;'],
  ['€', '&euro;'],
  ['™', '&trade;'],
  ['←', '&larr;'],
  ['↑', '&uarr;'],
  ['→', '&rarr;'],
  ['↓', '&darr;'],
  ['↔', '&harr;'],
  ['↵', '&crarr;'],
  ['∀', '&forall;'],
  ['∂', '&part;'],
  ['∃', '&exist;'],
  ['∅', '&empty;'],
  ['∇', '&nabla;'],
  ['∈', '&isin;'],
  ['∉', '&notin;'],
  ['∋', '&ni;'],
  ['∏', '&prod;'],
  ['∑', '&sum;'],
  ['−', '&minus;'],
  ['∗', '&lowast;'],
  ['√', '&radic;'],
  ['∝', '&prop;'],
  ['∞', '&infin;'],
  ['∠', '&ang;'],
  ['∧', '&and;'],
  ['∨', '&or;'],
  ['∩', '&cap;'],
  ['∪', '&cup;'],
  ['∫', '&int;'],
  ['∴', '&there4;'],
  ['∼', '&sim;'],
  ['≅', '&cong;'],
  ['≈', '&asymp;'],
  ['≠', '&ne;'],
  ['≡', '&equiv;'],
  ['≤', '&le;'],
  ['≥', '&ge;'],
  ['⊂', '&sub;'],
  ['⊃', '&sup;'],
  ['⊄', '&nsub;'],
  ['⊆', '&sube;'],
  ['⊇', '&supe;'],
  ['⊕', '&oplus;'],
  ['⊗', '&otimes;'],
  ['⊥', '&perp;'],
  ['⋅', '&sdot;'],
  ['⌈', '&lceil;'],
  ['⌉', '&rceil;'],
  ['⌊', '&lfloor;'],
  ['⌋', '&rfloor;'],
  ['◊', '&loz;'],
  ['♠', '&spades;'],
  ['♣', '&clubs;'],
  ['♥', '&hearts;'],
  ['♦', '&diams;']
];

//replaces symbol in a string with its entity
export function escapeEntities(str: string) {
  for (const [symbol, entity] of entities) {
    str = str.replaceAll(symbol, entity);
  }
  return str;
}

//replace &diams; with ♦
export function unescapeEntities(str: string) {
  for (const [symbol, entity] of entities) {
    str = str.replaceAll(entity, symbol);
  }
  return str;
}

//replaces &amp;hearts with &hearts;
//for situations when & is escaped twice
export function unescapeExtraAmp(str: string) {
  for (const [, entity] of entities) {
    const extraAmpEntity = entity.replace('&', '&amp;');
    str = str.replaceAll(extraAmpEntity, entity);
  }
  return str;
}
