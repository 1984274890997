import { HtmlElementAttributes } from '../types';

/**
 * Define attributes for text nodes
 */
export default function specifyTextNodeProperties(
  el: HTMLElement,
  nodeAttributes: HtmlElementAttributes
) {
  switch (el.nodeName) {
    case 'STRONG':
      return { strong: true };
    // nodeAttributes.strong = true;
    // break;
    case 'B':
      return { bold: true };
    // nodeAttributes.bold = true;
    // break;
    case 'SUP':
      return { sup: true };
    // nodeAttributes.sup = true;
    // break;
    default:
  }
}
