export const GenericIcon = (props: any) => {
  const { alt, title, src, className = '' } = props;
  return <img draggable="false" alt={alt} title={title} src={src} className={className} />;
};

export const CopyNodeBtn = () => (
  <GenericIcon src="/icons/copy.svg" alt="Copy node" title="Copy node" />
);
export const AddNodeBtn = () => (
  <GenericIcon src="/icons/plus.svg" alt="Add node" title="Add node" />
);
export const RemoveNodeBtn = () => (
  <GenericIcon
    src="/icons/trash-xmark.svg"
    alt="Remove node"
    title="Remove node"
  />
);
export const MoveUpBtn = () => (
  <GenericIcon
    src="/icons/angle-up.svg"
    alt="Move node up"
    title="Move node up"
  />
);
export const MoveDownBtn = () => (
  <GenericIcon
    src="/icons/angle-down.svg"
    alt="Move node down"
    title="Move node down"
  />
);

export const ExpandBtn = (props: any) => {
  const { className = '' } = props;
  return (
    <GenericIcon
      src="/icons/expand.svg"
      alt="expand"
      title="expand"
      className={className}
    />
  );
};

export const CollapseBtn = (props: any) => {
  const { className = '' } = props;
  return (
    <GenericIcon
      src="/icons/collapse.svg"
      alt="collapse"
      title="collapse"
      className={className}
    />
  );
};

export const UndoBtn = (props: any) => {
  const { className = '', darkMode } = props;
  return (
    <GenericIcon
      src={`/icons/undo-${darkMode ? 'white' : 'black'}.svg`}
      alt="undo"
      title="undo"
      className={className}
    />
  );
};

export const RedoBtn = (props: any) => {
  const { className = '', darkMode } = props;
  return (
    <GenericIcon
      src={`/icons/redo-${darkMode ? 'white' : 'black'}.svg`}
      alt="redo"
      title="redo"
      className={className}
    />
  );
};
