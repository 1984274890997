import { useRef } from 'react';
import { useDrag } from 'react-dnd';
import { useSlateStatic } from 'slate-react';
import { getSameTypeSiblingCluster, isAncestorPlaceholder } from '../helpers';

const classes = {
  canDrag: '[&_>_div_>_.element-header]:hover:!cursor-grab',
  dragging: 'hover:!cursor-grabbing'
};

export default function Draggable(props: any) {
  const { element: node, name, path, className } = props;
  const editor = useSlateStatic();
  const ref = useRef<HTMLDivElement>(null);
  const adjSameTypeSiblings = getSameTypeSiblingCluster(editor, node) as any;
  //exclude nodes that are "placeholder" AKA ignored by the editor
  const nonPlaceholderSameTypeSiblings = adjSameTypeSiblings.filter(
    (s: any) => !s.placeholder
  );
  const min = node?.config?.constraints?.min || 0;
  //determine if dragged node can be removed from its container and if it can be added to new container
  const canRemoveFromSrcContainer = nonPlaceholderSameTypeSiblings.length > min;
  const ancestorIsPlaceholder = isAncestorPlaceholder(editor, node);
  const isPlaceholderNode = !!node.placeholder || ancestorIsPlaceholder;
  //determine if at a minimum a node can be moved within its container
  const allowMovement = (downward: boolean = true): Boolean => {
    if (nonPlaceholderSameTypeSiblings.length <= 1 || isPlaceholderNode) {
      return false;
    }
    const currentIndex = nonPlaceholderSameTypeSiblings.indexOf(node);
    //disallow down if it's the last node
    if (downward) {
      return currentIndex !== nonPlaceholderSameTypeSiblings.length - 1;
    }
    //disallow up if it's the first node
    return currentIndex !== 0;
  };

  const [{ canDrag, isDragging }, drag] = useDrag(
    () => ({
      type: 'any',
      //can move in its own container in either direction or can leave that container
      canDrag: (!isAncestorPlaceholder(editor, node) &&
        (allowMovement(true) ||
          allowMovement(false) ||
          canRemoveFromSrcContainer)) as any,
      item: { element: node, name, path },
      collect: (monitor) => ({
        item: monitor.getItem(),
        isDragging: !!monitor.isDragging(),
        canDrag: !!monitor.canDrag()
      })
    }),
    [node, path, name]
  );
  drag(ref);

  const classNames = [
    className,
    canDrag ? classes.canDrag : '',
    isDragging ? classes.dragging : ''
  ].join(' ');

  return (
    <div ref={ref} className={classNames}>
      {props.children}
    </div>
  );
}
