import { Descendant, Text } from 'slate';

import { getHighlightClass, renderStaticNode } from '../../utils';

import {
  RenderableNode,
  defaultElementRenderer,
  renderText,
  voidNodes
} from './default-node-renderer';

import { LayersTreeNode, LayersTypelessNode } from '../types';

const serializeHtmlElement = (node: LayersTypelessNode) => {
  //ignore placeholder nodes
  if (node.placeholder) return '';
  if (Text.isText(node)) {
    return renderText(node);
  }
  // const stringifiedAttributes = stringifyMappedAttributes(node.attributes);

  if (!(node as LayersTypelessNode).type) {
    return defaultElementRenderer(node as Descendant);
  }

  switch (node.type) {
    case 'component':
    case 'container':
      // case 'cell':
      const {
        isDummy,
        htmlTag: Node = 'div',
        attributes,
        unique_id
      } = node as RenderableNode;

      let updatedAttributes = {
        ...attributes
      };

      const highlightClassName = getHighlightClass(node);
      const additionalClassNames = [highlightClassName].join(' ');

      updatedAttributes.className = [
        updatedAttributes.className,
        additionalClassNames
      ]
        .join(' ')
        .trim();

      if (voidNodes.includes(Node)) {
        return <Node key={unique_id} {...updatedAttributes} />;
      }

      if (isDummy) {
        return (
          <Node
            key={unique_id}
            {...updatedAttributes}
            dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          ></Node>
        );
      }

      const children = node.children.reduce((acc: any, n: LayersTreeNode) => {
        const serializedElement = serializeHtmlElement(n);
        if (serializedElement !== '') acc.push(serializedElement);
        return acc;
      }, []);

      return (
        <Node key={unique_id} {...updatedAttributes}>
          {children}
        </Node>
      );

    default:
      return defaultElementRenderer(node as Descendant);
  }
};

const serializeHtml = (node: Descendant) => {
  const serialized = serializeHtmlElement(node);
  const html = renderStaticNode(serialized);
  return html;
};

export default serializeHtml;
