import { Editor, Text, Transforms } from 'slate';

import {
  LayersComponentNode,
  LayersContainerNode,
  LayersEditor,
  LayersElementNode,
  LayersNode,
  LayersNodeConfig,
  LayersNodeEntry
} from '../types';

import nodeMatches from './node-matches';

function isElement(node: LayersNode): node is LayersElementNode {
  return (node as LayersElementNode).type === 'element';
}
function isContainer(node: LayersNode): node is LayersContainerNode {
  return (node as LayersContainerNode).type === 'container';
}
function isComponent(node: LayersNode): node is LayersComponentNode {
  return (node as LayersComponentNode).type === 'component';
}

/**
 * Convert LayersNodeConfig to LayersNode
 */
const sanitizeConfigNodeForInsertion = (
  config: LayersNodeConfig
): LayersNode => {
  const { exactMatch, looseMatch, children } = config;

  return {
    ...exactMatch,
    ...looseMatch,
    children: children.map((childConfig): LayersNode => {
      return sanitizeConfigNodeForInsertion(childConfig);
    })
  };
};

const normalizeContainer = (
  editor: LayersEditor,
  entry: LayersNodeEntry,
  config: LayersNodeConfig
) => {
  const [node, path] = entry;
  const children = node.children || [];

  // Remove unexpected nodes
  children.forEach((childNode, childNodeIdx) => {
    if (Text.isText(childNode)) {
      return;
    }
    const foundConfig = config.children.find((childConfig) =>
      nodeMatches(childNode as LayersNode, childConfig)
    );

    // Unexpected node
    /**
     * triple click to select text could go out of bounds
     * so when pasting back, it may be wrapped inside a node
     * so if a node doesn't have a right config, check its child too
     * if that child does have a config, just take it out of the parent
     */
    let foundGrandChild = false;
    if (!foundConfig) {
      if (childNode.children.length === 1) {
        const grandChildNode = childNode.children[0];
        const grandChildNodeConfig = config.children.find((childConfig) =>
          nodeMatches(grandChildNode as LayersNode, childConfig)
        );
        if (!!grandChildNodeConfig) {
          Transforms.unwrapNodes(editor, { at: path.concat(childNodeIdx) });
          foundGrandChild = true;
        }
      }
      if (!foundGrandChild) {
        console.log('Removing unexpected node', {
          childNode,
          path: path.concat(childNodeIdx)
        });
        Transforms.removeNodes(editor, { at: path.concat(childNodeIdx) });
      }
    }
  });

  // Cleanup children based on min/max
  config.children.forEach((childConfig, childConfigIdx) => {
    const { constraints } = childConfig;
    const { min = 0, max = 0 } = constraints || {};

    // Unlimited POWERRR
    if ((min === 0 && max === 0) || (!isNaN(min) && !isNaN(max))) {
      return;
    }

    // Find related nodes
    const nodesForConfig = children.filter((node: LayersNode) => {
      return nodeMatches(node, childConfig);
    });

    // Insert based on `min`
    if (nodesForConfig.length < min) {
      let sanitizedNode = sanitizeConfigNodeForInsertion(childConfig);

      // Ensure at least an empty text element
      if ((sanitizedNode.children || []).length === 0) {
        sanitizedNode.children = [{ text: '' }];
      }

      // Insert node
      const atPath = path.concat(childConfigIdx + nodesForConfig.length);
      Transforms.insertNodes(editor, sanitizedNode, {
        at: atPath
      });
      console.log('Inserting node', { sanitizedNode, atPath });
    }
    // Remove based on `max`
    else if (nodesForConfig.length > max) {
      // Remove children in reverse
      for (var i = children.length - 1; i > max; --i) {
        console.log('Removing node exceeding max allowed', children[i]);
        Transforms.removeNodes(editor, { at: path.concat(i) });
      }
    }
  });
};

/**
 * Normalizes the editor given a configuration.
 *
 * Expected use: withDefaultLayersNormalization(config)
 */
export default function defaultNormalizer(editor: LayersEditor) {
  const { normalizeNode } = editor;

  editor.normalizeNode = (entry: LayersNodeEntry) => {
    const [node, path] = entry;

    // No editor normalizers yet
    if (Editor.isEditor(node)) {
      return normalizeNode(entry);
    }

    if (Text.isText(node)) {
      return normalizeNode(entry);
    }

    // const nodeConfig = findNodeConfig(editor, entry, config);
    const nodeConfig = node.config;
    if (!nodeConfig) {
      console.log('No config found for entry', { node, path });
      return normalizeNode(entry);
    }

    if (isElement(node)) {
      // Nothing to do? Ideas:
      // - Some attributes could be enforced
      // - Appropriate default values
    } else if (isContainer(node) || isComponent(node)) {
      normalizeContainer(editor, entry, nodeConfig);
    }
  };

  return editor;
}
