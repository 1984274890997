import { useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Path } from 'slate';
import { useSlateStatic } from 'slate-react';
import {
  getParent,
  getPath,
  getPrevPath,
  getSameTypeSiblingCluster,
  moveNodes
} from '../helpers';
import { LayersEditor } from '../slatejs';

const canAddToTarget = (editor: LayersEditor, node: any): boolean => {
  const adjSameTypeSiblings = getSameTypeSiblingCluster(editor, node) as any;
  //exclude nodes that are "placeholder" AKA ignored by the editor
  const nonPlaceholderSameTypeSiblings = adjSameTypeSiblings.filter(
    (s: any) => !s.placeholder
  );
  const min = node?.config?.constraints?.min || 0;
  const max = node?.config?.constraints?.max || 0;
  const noLimit = (min === 0 && max === 0) || max < min;
  //determine if dragged node can be removed from its container and if it can be added to new container
  return nonPlaceholderSameTypeSiblings.length < max || noLimit;
};

const isSameType = (node: any, draggedNode: any) => {
  return node?.config?.name === draggedNode?.config?.name;
};

export default function Droppable(props: any) {
  const { nodeBefore, nodeAfter, path, className } = props;
  const editor = useSlateStatic();
  const ref = useRef<HTMLDivElement>(null);
  const canAddToTargetContainer =
    canAddToTarget(editor, nodeBefore) || canAddToTarget(editor, nodeAfter);

  const [useDropCollected, drop] = useDrop<any>(
    () => ({
      accept: 'any',
      canDrop: (item, monitor) => {
        const { element: node } = item;
        if (!isSameType(node, nodeBefore) && !isSameType(node, nodeAfter)) {
          return false;
        }
        const min = node?.config?.constraints?.min || 0;
        const adjSameTypeSiblings = getSameTypeSiblingCluster(
          editor,
          node
        ) as any;
        //exclude nodes that are "placeholder" AKA ignored by the editor
        const nonPlaceholderSameTypeSiblings = adjSameTypeSiblings.filter(
          (s: any) => !s.placeholder
        );
        const canRemoveFromSrcContainer =
          nonPlaceholderSameTypeSiblings.length > min;
        const targetParent = getParent(editor, path);
        const targetParentPath = getPath(editor, targetParent as any) || [];
        const srcParent = getParent(editor, item.path);
        const srcParentPath = getPath(editor, srcParent as any) || [];
        const isSameParent = Path.equals(targetParentPath, srcParentPath);
        //if draggedNode goes to new container but the constraints don't allow it, abort
        if (!isSameParent) {
          if (!canAddToTargetContainer || !canRemoveFromSrcContainer) {
            return false;
          }
        }
        return true;
      },
      drop: (item, monitor) => {
        try {
          if (!ref.current) {
            return;
          }
          // Don't replace items with themselves
          if (Path.equals(item.path, path)) {
            return;
          }
          //if from above slate handles the reordering differently so go to previous path instead
          const fromAbove = Path.isBefore(item.path, path);
          if (fromAbove) {
            moveNodes(editor, item.path, getPrevPath(path));
          } else {
            moveNodes(editor, item.path, path);
          }
        } catch (e) {}
      },
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop()
      })
    }),
    [path, nodeBefore, nodeAfter]
  );

  const { isOver, canDrop } = useDropCollected as any;
  drop(ref);
  const classNames = [className];
  const droppable = 'bg-green-500';
  const undroppable = 'bg-red-500';
  if (isOver) {
    classNames.push(canDrop ? droppable : undroppable);
  }
  return (
    <div ref={ref} className={classNames.join(' ')}>
      {props.children}
    </div>
  );
}
