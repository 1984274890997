export * from './handlers';

export * from './types';

const layersHoveredNodeClass = 'LAYERS-hovered-node';
const layersFocusedNodeClass = 'LAYERS-focused-node';
const layersEditableNodeClass = 'LAYERS-editable-node';
const layersHoveredTextClass = 'LAYERS-hovered-node-text';

const layersReadonlyEditorHoverClass = 'LAYERS-hover-active';
const layersReadonlyEditorFocusClass = 'LAYERS-focus-active';

const layershoveredNodeProperty = 'hovered';
const layersfocusedNodeProperty = 'focused';

const layersOverlayFocusId = 'LAYERS-overlay-focus';
const layersOverlayHoverId = 'LAYERS-overlay-hover';
const layersOverlayHighlightClass = 'LAYERS-highlight-overlay';

export {
  layersEditableNodeClass,
  layersFocusedNodeClass,
  layersHoveredNodeClass,
  layersHoveredTextClass,
  layersOverlayFocusId,
  layersOverlayHighlightClass,
  layersOverlayHoverId,
  layersReadonlyEditorFocusClass,
  layersReadonlyEditorHoverClass,
  layersfocusedNodeProperty,
  layershoveredNodeProperty
};
