import { useSlateStatic } from 'slate-react';

import { getDisplayName, getPath } from '../../../helpers';

import AddRemoveNodesBtns from '../../../components/addRemoveNodesBtns';
import Draggable from '../../../components/draggable';
import { useElementHoverListeners } from '../partials';
import ElementHeader from './element-header';

import AddNodeDividerWrapper from '../../../components/addNodeDivider/addNodeDividerWrapper';
const classes = {
  root: 'text-[#757575] dark:text-white bg-[#eeeeee] dark:bg-[#808080] border border-primaryGrey border-l-[5px] border-l-primaryGrey py-0 px-[5px] my-0 mx-[5px] [&_.containerHeader]:m-0 [&_.contents]:border-primaryGrey [&_.contents]:border',
  headerContainer:
    'm-0 py-[10px] px-[5px] flex flex-row gap-[15px] items-center justify-between',
  placeholder:
    'border border-primaryGrey/50 border-l-[5px] border-l-primaryGrey/50 [&_>_div_>_div:not(.node-btns):not(&_>_.header)]:opacity-50 [&_>_div_>_.header]:bg-disabledGrey/50 [&_>_div_>_.header_>_h4]:opacity-100 [&_>_div_>_.header_>_h4]:dark:opacity-50'
};

export const Dummy = (props: any) => {
  const { attributes, element, children } = props;
  const { attributes: htmlAttributes, htmlTag = 'container' } = element;
  const { className = '' } = htmlAttributes;
  const containerName = className ? `${htmlTag}.${className}` : htmlTag;
  const editor = useSlateStatic();
  const classNames = [classes.root];
  if (!!element.placeholder) classNames.push(classes.placeholder);
  const path = getPath(editor, element) || [];
  const displayName = getDisplayName(editor, element, path);
  const { onMouseEnter, onMouseLeave } = useElementHoverListeners(
    editor,
    element,
    false
  );
  const name = element?.config?.name;
  const headerClassNames = [classes.headerContainer, 'element-header'].join(' ');
  return (
    <AddNodeDividerWrapper element={element} path={path}>
      <Draggable element={element} name={name} path={path}>
        <div
          {...attributes}
          className={classNames.join(' ')}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className={headerClassNames} contentEditable={false}>
            <ElementHeader name={`${displayName || containerName}`} />
            <AddRemoveNodesBtns editor={editor} element={element} />
            {children}
          </div>
        </div>
      </Draggable>
    </AddNodeDividerWrapper>
  );
};

export default Dummy;
