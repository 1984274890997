import { CollapseBtn, ExpandBtn } from './icons';

const classes = {
  root: 'absolute top-0 left-0 w-12 h-12 flex justify-start opacity-50 hover:opacity-100 z-[3] top-[5%] left-[5%]',
  figure:
    'w-8 h-8 bg-white flex justify-center items-center shadow-roundedBlack mt-px ml-px mr-0 mb-0 rounded-3xl hover:cursor-pointer [&_img]:w-4 [&.hidden]:hidden'
};

export default function FullscreenBtn(props: any) {
  const { setter, state } = props;
  return (
    <div className={classes.root}>
      <figure onClick={() => setter(!state)} className={classes.figure}>
        {!state && <ExpandBtn />}
        {!!state && <CollapseBtn />}
      </figure>
    </div>
  );
}
