import { useEditor, useSelected } from 'slate-react';
import useEditableFieldFocusHighlight from './partials/use-editable-focus-highlight';
import Typography from '../../components/typography';

const LeafRenderer = (props: any) => {
  const editor = useEditor();
  const selected = useSelected();

  let { attributes, children, leaf, text } = props;

  if (!!leaf.sup) {
    children = <sup>{children}</sup>;
  }
  if (!!leaf.bold) {
    children = <b>{children}</b>;
  }
  if (!!leaf.strong) {
    children = <strong>{children}</strong>;
  }

  // Highlight detection & focus field
  useEditableFieldFocusHighlight(editor, text, selected);

  const RenderedLeaf = (
    <Typography variant="body1" component={'span'} {...attributes}>
      {children}
    </Typography>
  );

  return RenderedLeaf;
};

export default LeafRenderer;
