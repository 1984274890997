import uniqid from 'uniqid';

import { jsx } from 'slate-hyperscript';

import { escapeEntities } from '../../helpers/escapeEntities';
import mapHtmlElementAttributes from './map-element-attributes';

/**
 * Creates a Slate text node
 * - text: plain-text content
 * - props:
 *   - attributes: HTML attributes (in case of sup, span, strong, b)
 *   - other_attrs: booleans for sup, strong, bold
 */
export function createJsxText(element: HTMLElement, props: any) {
  const { attributes = {}, childIndex, ...other_attrs } = props;
  let text = escapeEntities(element.textContent || '');
  //remove all spaces greater than one space
  let formattedText = text.replace(/\s{2}/g, '');
  //if it's the first leaf, remove any leading space if it exists
  if (childIndex === 0 && formattedText.startsWith(' ')) {
    formattedText = formattedText.substring(1);
  }
  return jsx('text', { attributes, ...other_attrs }, formattedText);
}

/**
 * Creates a Slate element node with type "component"
 * - element: the HTML DOM node in question, expected to have the
 *            `data-component-id` attribute
 * - props: any additional props, such as `name`
 * - children: any child nodes
 */
export function createJsxComponent(
  element: HTMLElement,
  props: any,
  children: Array<any>
) {
  const component_id = element.getAttribute('data-component-id') || 'unnamed';
  const elProps = { type: 'component', id_prefix: component_id, ...props };
  return createJsxElementHelper(element, elProps, children);
}

/**
 * Creates a Slate element node with type "container"
 * - element: the HTML DOM node in question, expected to have the
 *            `data-component-id` attribute
 * - props: any additional props, such as `name`
 * - children: any child nodes
 */
export function createJsxContainer(
  element: HTMLElement,
  props: any,
  children: Array<any>
) {
  const elProps = { type: 'container', ...props };
  return createJsxElementHelper(element, elProps, children);
}

/**
 * Creates a Slate element node with type "field", editable by the user
 * - element: the HTML DOM node in question, expected to have the
 *            `data-component-id` attribute
 * - props: any additional props, such as `name`
 * - children: any child nodes
 */
export function createJsxElement(
  element: HTMLElement,
  props: any,
  children: Array<any>
) {
  const elProps = { type: 'element', ...props };
  return createJsxElementHelper(element, elProps, children);
}

/**
 * A way to enforce "element" use.
 *
 * Expected parameters:
 * - element: the HTML DOM node in question
 * - props:
 *   - id_prefix: prefix for unique id
 *   - name: any additional props, such as `name`
 * - children: any child nodes
 */
export function createJsxElementHelper(
  element: HTMLElement,
  props: any,
  children: Array<any>
) {
  const htmlTag = element.nodeName.toLowerCase();
  const { id_prefix = htmlTag, ...other_attrs } = props;
  const unique_id = uniqid(`${id_prefix}-`);

  let attributes = mapHtmlElementAttributes(element) as any;

  // Ensure alt tag for img elements
  if (htmlTag === 'img' && !attributes.alt) {
    attributes.alt = '';
  }

  if (!children || children.length === 0) {
    children = [{ text: '' }];
  }

  return jsx(
    'element',
    {
      unique_id,
      htmlTag,
      attributes,
      ...other_attrs
    },
    children
  );
}
