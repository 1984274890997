import { kebabCaseToCamel } from '../../utils';

/**
 * Converts a style string to a map for use with React.
 * e.g. converts from "display: flex; margin-left: 5px"
 *   to { display: 'flex', marginLeft: '5px' }
 */
export function styleStringToObject(styleStr: string) {
  return (
    styleStr
      // Get each "property: value" pair
      .split(/; ?/)
      // Create style map: { display: 'flex', ... }
      .reduce((acc, style) => {
        // Split "property: value"
        let [property, value] = style.split(/: ?/);

        // Disregard empty properties
        if (!property) {
          return acc;
        }

        // Prepare conversion to react component
        property = kebabCaseToCamel(property);
        return {
          ...acc,
          [property]: value
        };
      }, {})
  );
}
