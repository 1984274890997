import {
  HtmlElementAttributes,
  LayersNode,
  LayersNodeConfig,
  LayersTypelessNode
} from '../types';

import {
  createJsxComponent,
  createJsxContainer,
  createJsxElement,
  createJsxElementHelper,
  createJsxText
} from './layers-nodes';

import { getUniqueCurrentChildren } from '../../helpers';
import { voidNodes } from './default-node-renderer';
import mapHtmlElementAttributes from './map-element-attributes';
import nodeMatches from './node-matches';
import specifyTextNodeProperties from './specify-text-node-properties';

const markHtmlElements = ['SUP', 'B', 'STRONG'];

// &nbsp; === &#160;
const nbsp = '&nbsp;';

const findNodeConfig = (
  node: LayersTypelessNode,
  parentConfig: LayersNodeConfig,
  recursive: Boolean = true,
  currentChildren: LayersNode[]
): [LayersNodeConfig, number] | null => {
  const uniqueChildren = getUniqueCurrentChildren(currentChildren);
  const configIdx = uniqueChildren.length;
  const configType = parentConfig?.exactMatch?.type;
  // Temporarily apply type to node as it is typeless while deserializing
  const typedNode = {
    ...node,
    type: configType || 'container',
    // ...(!!configType ? { type: configType } : {}),
    children: []
  };

  try {
    if (nodeMatches(typedNode, parentConfig)) {
      return [parentConfig, configIdx];
    }
  } catch (e) {
    console.error(e);
    return null;
  }

  if (recursive) {
    // Assume previous node was configured properly, and
    // use its configIdx to start the search
    const previousNode = currentChildren[currentChildren.length - 1];

    // When the previous node is the same as this one, use its config and configIdx
    if (previousNode?.config) {
      if (findNodeConfig(node, previousNode?.config, false, [])) {
        return [previousNode.config as any, previousNode.configIdx as any];
      }
    }
    for (
      let childConfigIdx = previousNode?.configIdx
        ? previousNode.configIdx + 1
        : 0;
      childConfigIdx < parentConfig.children.length;
      ++childConfigIdx
    ) {
      const childConfig = parentConfig.children[childConfigIdx];

      const foundConfig = findNodeConfig(
        typedNode,
        childConfig,
        false,
        currentChildren
      );
      if (foundConfig) {
        return foundConfig;
      }
    }
  }
  return null;
};

/**
 * Creates a plain structure without children for use
 * with `findNodeConfig`
 */
const scaffoldNodeObject = (
  element: HTMLElement,
  nodeAttributes: HtmlElementAttributes
) => {
  let nodeObj: any = {
    htmlTag: element.nodeName.toLowerCase(),
    attributes: nodeAttributes,
    children: []
  };

  if (element.innerHTML === nbsp || voidNodes.includes(nodeObj.htmlTag)) {
    nodeObj.isDummy = true;
  }

  return nodeObj;
};

type DeserializationAttributes = {
  parentConfig: LayersNodeConfig;
};

/**
 * Delegates HTML deserialization given a component configuration.
 */
const deserializeHtml =
  (config: LayersNodeConfig) =>
  (element: HTMLElement, deserializationAttributes = {}) =>
    _deserializeHtml(
      [],
      element,
      {
        ...deserializationAttributes,
        parentConfig: config
      },
      0
    );

// "General" deserializer
function _deserializeHtml(
  currentChildren: LayersNode[],
  htmlElement: HTMLElement,
  deserializationAttributes: DeserializationAttributes,
  childIndex: number
): LayersNode | null {
  const { parentConfig } = deserializationAttributes;
  // Plain text nodes
  const isTextNode =
    htmlElement.nodeType === Node.TEXT_NODE ||
    markHtmlElements.includes(htmlElement.nodeName);
  if (isTextNode) {
    const textContent = htmlElement.textContent;
    const trimmed = (textContent || '').trim();
    // Ignore empty content
    if (trimmed === '\n' || trimmed.length === 0) {
      return null;
    }
    const enhancedMarkAttributes = {
      ...deserializationAttributes,
      ...specifyTextNodeProperties(htmlElement, {})
    };
    return createJsxText(htmlElement, {
      childIndex,
      ...enhancedMarkAttributes
    });
  }
  // Skip non-elements
  else if (htmlElement.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = mapHtmlElementAttributes(htmlElement);
  const searchableNodeObj = scaffoldNodeObject(htmlElement, nodeAttributes);

  // Find componentConfig
  const foundIndexedConfig = findNodeConfig(
    searchableNodeObj,
    parentConfig,
    true,
    currentChildren
  );
  // if 404, do not add element (ignore it)?
  if (!foundIndexedConfig) {
    console.log(
      'No config found for node during deserialization',
      searchableNodeObj
    );
    return null;
  }

  const [foundConfig, foundConfigIdx] = foundIndexedConfig;

  const nodeMarkAttributes = Object.assign(
    {},
    {
      ...deserializationAttributes,
      parentConfig: foundConfig
    }
  );

  // Prepare children first
  let children = Array.from(htmlElement.childNodes)
    .reduce((acc: any[], node, i) => {
      const deserializedNode = _deserializeHtml(
        acc,
        node as HTMLElement,
        nodeMarkAttributes,
        i
      );

      // Do not add nulls
      if (!deserializedNode) {
        return acc;
      }

      return [...acc, deserializedNode];
    }, [])
    .flat() as LayersNode[];

  const general_props = {
    config: foundConfig,
    configIdx: foundConfigIdx
  };

  // otherwise, create a component, container, element, or text based on attributes
  switch (foundConfig?.exactMatch?.type) {
    case 'component':
      return createJsxComponent(htmlElement, general_props, children);
    case 'container':
      return createJsxContainer(
        htmlElement,
        { ...general_props, isDummy: searchableNodeObj.isDummy },
        children
      );
    case 'element':
      return createJsxElement(htmlElement, general_props, children);

    // Applicable to inline nodes such as sup, b, strong

    case 'text':
      if (markHtmlElements.includes(htmlElement.nodeName)) {
        const enhancedMarkAttributes = {
          inlineElement: true,
          ...markHtmlElements,
          ...specifyTextNodeProperties(htmlElement, {})
        };

        return createJsxText(htmlElement, {
          ...enhancedMarkAttributes,
          ...general_props,
          attributes: nodeAttributes
        });
      }
      return unexpectedElementFound(htmlElement);

    // Allow fallthrough
    // eslint-disable-next-line no-fallthrough
    default:
      return unexpectedElementFound(htmlElement);
  }

  function unexpectedElementFound(element: HTMLElement) {
    console.log('Unexpected element: ' + element.nodeName);
    // throw new Error('Unexpected element: ' + element.nodeName);
    return null;
  }
}

export default deserializeHtml;
