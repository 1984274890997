// Import React dependencies.
import React, { useCallback, useEffect, useRef, useState } from 'react';

// Import the Slate editor factory.
import { createEditor, Descendant, Editor, Transforms } from 'slate';
// Import the Slate components and React plugin.
import parserHtml from 'prettier/parser-html';
import prettier from 'prettier/standalone';
import { withHistory } from 'slate-history';
import { Editable, Slate, withReact } from 'slate-react';

import {
  deserializeHtmlString,
  LeafRenderer,
  renderComponentChildren,
  serializeHtml
} from '../utils';

import {
  defaultNode,
  handleOnKeyDown,
  handleOnKeyUp,
  layersEditableNodeClass,
  LayersEditor,
  layersOverlayFocusId,
  layersOverlayHighlightClass,
  layersOverlayHoverId,
  layersReadonlyEditorFocusClass,
  layersReadonlyEditorHoverClass,
  withNormalizedComponents
} from '../slatejs';

import Toggle from '../components/toggle';
import { HoveringToolbar } from '../components/toolbar/hoveringToolbar';
import { getMappedComponentConfigNodes } from '../directus';

import ReadonlyHtmlPreview from '../slatejs/handlers/readonly-html-preview';
import FullscreenBtn from './fullscreenBtn';

import { layersFocusedNodeClass, layersHoveredNodeClass } from '../slatejs';
import { voidNodes } from '../slatejs/handlers/default-node-renderer';
import {
  ComponentConfigMap,
  LayersNode,
  LayersTreeNode
} from '../slatejs/types';

import ComponentSelector from './componentSelector';
import UndoRedoBtns from './undoRedoBtns';

import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import theme from '../config/theme';
import {
  DarkModeContext,
  ForceRenderContext,
  PlaceholderNodeContext
} from '../context';
import { getAdjacentTextSiblings, getNode, getPath } from '../helpers';
import { Toaster } from '../shadcn/components/toast/toaster';
import ComponentConfigs from './componentSchema';
import TwoAccordionContainer from './twoAccordionContainer';
import Typography from './typography';

//serialize: going from left to right (slate nodes to html format)
//deserialize: going from right to left (html format to slate nodes)

// Epson B2C styles
const defaultGlobalStyles: Array<string> = ['css/epson-styles.css'];
const classes = {
  //ensure these classnames match those in slatejs/index
  hoveredFocusRules: `[&_.LAYERS-hovered-node:not(.LAYERS-focused-node):not(.LAYERS-hovered-node-text)]:!bg-hoveredNode [&_.LAYERS-focused-node:not(.element-container)]:!bg-focusedNode`,
  main: 'w-full h-screen overflow-hidden flex flex-col',
  header:
    'h-[75px] box-border w-full fixed bg-black text-white flex flex-row items-center justify-between py-[10px] px-[30px] [&_h1]:m-0 [&_.togglesContainer]:gap-[25px] [&_.togglesContainer]:flex [&_.togglesContainer]:items-center',
  editor:
    'overflow-hidden flex justify-between flex-grow py-[0px] px-[30px] gap-[15px] bg-white dark:bg-darkMain',
  toolbar:
    'pb-[10px] absolute flex flex-row items-center justify-between top-[95px] w-[calc(50%-50px)]',
  editorLeft:
    'relative [&.editor]:overflow-y-scroll [&.editor]:dark:text-white [&.editor_input]:dark:bg-black [&.editor_input]:dark:text-white [&.editor_.leafContainer]:dark:bg-black',
  editorRight: 'max-h-full flex flex-col basis-2/4',
  componentHtmlAccordionChildren: 'h-full relative',
  textarea:
    'p-2 box-border w-full resize-y h-full [&.disabled]:opacity-75 dark:text-white dark:border-white bg-white dark:bg-editorRightDark',
  editorContainer:
    'mt-40 pt-2 flex-shrink-0 flex-grow-0 overflow-hidden gap-[15px] basis-[calc(50%-15px)]',
  previewContainer: 'h-full w-full relative overflow-hidden',
  fullscreen:
    'absolute right-0 bottom-0 top-0 left-0 w-full h-full flex justify-center items-center bg-black/[0.7]'
};

const withEditableVoids = (editor: LayersEditor) => {
  const { isVoid } = editor;

  // Voids are in charge of rendering their own children
  editor.isVoid = (node: LayersTreeNode) => {
    if (
      (node.type === 'element' && voidNodes.includes((node as any).htmlTag)) ||
      node.isDummy
    ) {
      return true;
    }

    return isVoid(node);
  };

  return editor;
};

//to make the html in the right editor more readable
const prettifyHtml = (html: string) => {
  try {
    return prettier.format(html, {
      parser: 'html',
      plugins: [parserHtml],
      printWidth: 70,
      bracketSameLine: true,
      htmlWhitespaceSensitivity: 'ignore'
    });
  } catch (error) {
    return html;
  }
};

const createDefaultGlobalInlineCss = (colors: any) => `
.${layersReadonlyEditorHoverClass} .${layersEditableNodeClass}.${layersHoveredNodeClass},
.${layersReadonlyEditorFocusClass} .${layersEditableNodeClass}.${layersFocusedNodeClass}{
  opacity: 1 !important;
}
.${layersReadonlyEditorHoverClass} .${layersOverlayHighlightClass},
.${layersReadonlyEditorFocusClass} .${layersOverlayHighlightClass}{
  display: block;
  position: absolute;
  opacity: 0.5;
}
#${layersOverlayFocusId}{ background: ${colors.focusedNode}; z-index: 2 }
#${layersOverlayHoverId}{ background: ${colors.hoveredNode}; z-index: 1 }
`;

/**
 * Sanitize layers-only classnames & repercussions
 */
const removeHighlightClassFromHtmlString = (
  html: string,
  className: string
): string => {
  const regexp = new RegExp(
    `class="([^"]*?) ?(?:${className})+ ?([^"]*)"`,
    'g'
  );

  return (
    html
      // Remove highlight class
      .replaceAll(regexp, 'class="$1$2"')
      // Remove empty class attribute
      .replaceAll(/ class=""/g, '')
  );
};

const removeHighlightClassesFromHtmlString = (html: string): string => {
  const classNames = ' ?LAYERS-[^" ]+';
  return removeHighlightClassFromHtmlString(html, classNames);
};

const Main = (props: any) => {
  /**
   * when the last node of its kind is deleted, we need a visual representation of it to keep so it can be re-added
   * these nodes are ignored during serialization so we need to keep track of what to add back when deserializing
   * this is a map where the key is a stringified version of its path and the value is the node itself
   */
  const [placeholderNodes, setPlaceholderNodes] = useState({});
  const { darkMode, setDarkMode } = React.useContext(DarkModeContext) as any;
  const [forceRender, setForceRender] = useState(0);
  const [usePrettyHtml, setUsePrettyHtml] = useState(false);
  const [fullscreenPreview, setFullscreenPreview] = useState(false);
  //keep track of which keys are down and what the current target is
  const [keyState, setKeyState] = useState({
    target: null,
    downKeys: new Set()
  });

  //follow OS theme for default dark mode

  const renderNode = renderComponentChildren;

  const LeafRendererWithEditor = (props: any) => {
    const propsWithEditor = {
      ...props,
      editor
    };

    return <LeafRenderer {...propsWithEditor} />;
  };

  const [editor] = useState(() =>
    withNormalizedComponents(
      withEditableVoids(withReact(withHistory(createEditor())))
    )
  );

  const renderLeaf = useCallback(LeafRendererWithEditor, [editor]);

  const [loadedComponentConfigs, setLoadedComponentConfigs]: any = useState(
    {} as ComponentConfigMap
  );
  const [currentComponentId, setCurrentComponentId] = useState('');
  const [initialTree, setInitialTree] = useState<Descendant>({} as Descendant);
  const [viewComponentSchema, setViewComponentSchema] = useState(false);

  useEffect(() => {
    const { pathname } = window.location;
    if (pathname.startsWith('/schema')) {
      setViewComponentSchema(true);
    }
  }, []);

  // Define a rendering function based on the element passed to `props`. We use
  // `useCallback` here to memoize the function for subsequent renders.
  const deserialized =
    deserializeHtmlString('', loadedComponentConfigs) || defaultNode;
  const serialized = serializeHtml(deserialized);
  const [initialValue] = React.useState([deserialized] as Descendant[]);
  const [editableValue, setEditableValue] = React.useState(serialized);

  // Init configs
  useEffect(() => {
    loadComponentConfigs();
  }, []);

  // Editor change
  useEffect(() => {
    // Update HTML within editable text section
    // made edits on the left side and want them to show up on the right side too
    const { onChange: editorOnChange } = editor;
    editor.onChange = (options) => {
      const newSlateState = editor.children;
      // Re-setting the initial value should have no effect on state
      const htmlContent = serializeHtml(newSlateState[0]);
      setEditableValue(htmlContent);
      //used to re-render all leaves
      editorOnChange(options);
      setForceRender(forceRender + 1);
      const componentId =
        newSlateState[0].attributes?.['data-component-id'] || '';
      setCurrentComponentId(componentId);
    };
    //eslint-disable-next-line
  }, [editor]);

  //if component changes, don't carry over placeholder nodes from different component
  //also re-set initial tree
  useEffect(() => {
    setPlaceholderNodes({});
    setInitialTree(editor.children[0]);
    //eslint-disable-next-line
  }, [currentComponentId]);

  //made edits on the right side and want them to show up on the left side too
  const editableValueChanged = (newValue: string) => {
    setEditableValue(newValue);
    const newSlateState = [
      deserializeHtmlString(newValue, loadedComponentConfigs) || defaultNode
    ];

    const componentId =
      newSlateState[0].attributes?.['data-component-id'] || '';
    // ReactEditor.deselect(editor);
    // Replace contents with deserialized structure.
    // This is done in two steps on purpose - otherwise
    // the HTML editor does not update.
    Transforms.removeNodes(editor, { at: [0] });
    Transforms.insertNodes(editor, newSlateState);
    //if you're still in the same component, re-add placeholder nodes
    if (componentId === currentComponentId) {
      for (const [k, v] of Object.entries(placeholderNodes)) {
        Transforms.insertNodes(editor, v as any, { at: JSON.parse(k) });
      }
    }
    setCurrentComponentId(componentId);

    // The seemingly-ideal shorthand is left below for posterity.
    // Transforms.setNodes(editor, newSlateState, { at: [0] });

    // Remove focus
    Transforms.deselect(editor);
  };

  const onKeyDown = (event: any) => {
    handleOnKeyDown(event, editor, keyState, setKeyState);
  };

  const onKeyUp = (event: any) => {
    handleOnKeyUp(event, editor, keyState, setKeyState);
  };

  async function loadComponentConfigs() {
    const componentConfigs = await getMappedComponentConfigNodes();
    setLoadedComponentConfigs(componentConfigs);
  }

  const darkClass = !!darkMode ? 'dark' : '';
  const editorRef = useRef<HTMLDivElement>(null);
  const iframeContainerRef = useRef<HTMLDivElement>(null);
  //ensure nothing is focused in the editor when clicking elsewhere
  useEffect(() => {
    const handleClick = (e: any) => {
      const target = e.target;
      const isInEditor = !!target.closest('.editor');
      if (!isInEditor) {
        editor.deselect();
      }
    };
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    const shouldPreventSelection = (e: Event): boolean => {
      const windowSelection = window.getSelection();
      const anchor =
        windowSelection?.anchorNode?.parentElement?.closest?.('.field-input');
      const focus =
        windowSelection?.focusNode?.parentElement?.closest?.('.field-input');
      //if selection spans multiple nodes, prevent selection
      return !!anchor && !!focus && anchor !== focus;
    };

    //ensure selection for copy/paste only spans one node
    const handleCutCopy = async (e: Event) => {
      if (shouldPreventSelection(e)) {
        e.preventDefault();
        await navigator.clipboard.writeText('');
      }
      const target = e.target as any;
      if (!target.closest('.leafContainer')) {
        e.preventDefault();
        const text = window.getSelection()?.toString?.();
        await navigator.clipboard.writeText(text || '');
        return;
      }
    };
    const handleSelection = async (e: Event) => {
      if (shouldPreventSelection(e)) {
        Transforms.deselect(editor);
        window.getSelection()?.empty?.();
      }
    };

    const handleClickSelection = (e: Event) => {
      if ((e as any).detail >= 3) {
        e.preventDefault();
        const target = e.target as any;
        if (!target) {
          return;
        }
        const isSlateNode = !!target.closest('.leafContainer');
        //if triple click we want all text nodes in the field to be selected
        if (isSlateNode) {
          const { selection } = editor;
          //so get the path of the first selected text node
          const anchorPath = selection?.anchor?.path || [];
          const anchorNode = getNode(editor, anchorPath);
          //get its adjacent siblings
          const textSiblings = getAdjacentTextSiblings(
            editor,
            anchorNode as any
          );
          //get the path of the last one to define the range the editor should select
          const startPath = getPath(editor, textSiblings[0]);
          const endPath = getPath(editor, textSiblings.at(-1));
          if (!startPath || !endPath) {
            return;
          }
          const newSelection = {
            anchor: Editor.start(editor, startPath),
            focus: Editor.end(editor, endPath)
          };
          //absolutely necessary
          editor.deselect();
          setTimeout(() => {
            Transforms.select(editor, newSelection);
          }, 100);
        }
      }
    };

    //don't allow pasting a multiline element into one node
    const handlePaste = async (e: Event) => {
      const target = e.target as HTMLElement;
      const notSlateNode =
        !target.closest('.leafContainer') && target.tagName === 'INPUT';
      if (notSlateNode) {
        return;
      }
      e.preventDefault();
      const text = await navigator.clipboard.readText();
      const splitText = text.split('\n');
      const { selection } = editor;
      if (!selection) {
        return;
      }
      //replace selection with new text
      Transforms.insertText(editor, splitText[0], {
        at: selection
      });
    };
    const handleCopy = (e: Event) => handleCutCopy(e);
    const handleCut = (e: Event) => handleCutCopy(e);
    const handleSelect = (e: Event) => handleSelection(e);
    const handleClickSelect = (e: Event) => handleClickSelection(e);

    const eventMap = {
      cut: handleCut,
      copy: handleCopy,
      keydown: handleSelect,
      keyup: handleSelect,
      click: handleClickSelect,
      mousemove: handleSelect,
      paste: handlePaste
    };
    const editorRefCurrent = editorRef.current;
    if (!!editorRefCurrent) {
      Object.entries(eventMap).forEach(([k, v]) =>
        editorRefCurrent.addEventListener(k, v)
      );
    }
    return () => {
      if (!!editorRefCurrent) {
        Object.entries(eventMap).forEach(([k, v]) =>
          editorRefCurrent.removeEventListener(k, v)
        );
      }
    };
  }, [editorRef, editor]);

  const topmostComponent = editor.children[0] as LayersNode;
  const topmostComponentConfig = topmostComponent?.config;
  const { componentCss = '', componentHtmlContainer = '' } =
    topmostComponentConfig || {};
  // Pull color from theme
  const defaultGlobalInlineCss = createDefaultGlobalInlineCss(
    theme.colors || {}
  );

  const editableValueWithoutHighlightClass =
    removeHighlightClassesFromHtmlString(editableValue);

  const componentHtmlAccordionProps = (
    maxTextareaHeight: number,
    heightToUse: number,
    updatedRecently: boolean
  ): { children: any; states?: any } => {
    //if accordion is reset to a specific width (like half width, resize the textarea as well)
    return {
      states: {
        componentHtml: [
          !!usePrettyHtml
            ? prettifyHtml(editableValueWithoutHighlightClass)
            : editableValueWithoutHighlightClass,
          editableValueChanged
        ]
      },
      children: (
        <div
          className={`${classes.componentHtmlAccordionChildren} editor editor-html`}
        >
          <Typography
            variant="textarea"
            component="textarea"
            style={{
              maxHeight: `${maxTextareaHeight}px`,
              height: `${heightToUse}px`,
              transition: updatedRecently ? 'height 0.2s ease-out' : 'none'
            }}
            className={`${darkClass} ${classes.textarea} ${
              usePrettyHtml ? 'disabled' : ''
            }`}
            value={
              !!usePrettyHtml
                ? prettifyHtml(editableValueWithoutHighlightClass)
                : editableValueWithoutHighlightClass
            }
            onChange={(e: any) => editableValueChanged(e.target.value)}
            readOnly={usePrettyHtml}
            disabled={usePrettyHtml}
          />
        </div>
      )
    };
  };

  //resize state: state from the accordion so we can resize the iframe
  const componentPreviewAccordionProps = (
    accordionResizeState: any
  ): { children: any; states?: any } => ({
    states: { fullscreen: [fullscreenPreview, setFullscreenPreview] },
    children: (
      <div
        className={`${classes.previewContainer} ${
          fullscreenPreview ? classes.fullscreen : ''
        }`}
        ref={iframeContainerRef}
      >
        {!!fullscreenPreview && (
          <FullscreenBtn
            state={fullscreenPreview}
            setter={setFullscreenPreview}
          />
        )}
        <ReadonlyHtmlPreview
          fullscreen={fullscreenPreview}
          html={editableValue}
          globalCss={defaultGlobalInlineCss}
          componentCss={componentCss}
          componentHtmlContainer={componentHtmlContainer}
          globalStyles={defaultGlobalStyles}
          containerRef={iframeContainerRef}
          accordionResizeState={accordionResizeState}
        />
      </div>
    )
  });

  return (
    <main
      className={`${classes.main} ${darkClass} ${classes.hoveredFocusRules}`}
    >
      <header className={`${classes.header} header`}>
        <Typography variant="h1" className="lol">
          Code Editor
        </Typography>
        <div className="togglesContainer">
          <Toggle
            label={'Pretty HTML'}
            state={usePrettyHtml}
            setter={setUsePrettyHtml}
          />
          <Toggle label={'Dark Mode'} state={darkMode} setter={setDarkMode} />
        </div>
      </header>
      {!viewComponentSchema && (
        <section className={classes.editor}>
          <Slate editor={editor} value={initialValue}>
            <div className={classes.toolbar}>
              <ComponentSelector
                initialTree={initialTree}
                curComponent={topmostComponent}
                componentConfigs={loadedComponentConfigs}
                setter={editableValueChanged}
              />
              <UndoRedoBtns darkMode={darkMode} />
            </div>
            <div
              ref={editorRef}
              className={`editor editor-modular ${classes.editorLeft} ${classes.editorContainer} ${darkClass}`}
            >
              <HoveringToolbar darkClass={darkClass} editorRef={editorRef} />
              <PlaceholderNodeContext.Provider
                value={{ placeholderNodes, setPlaceholderNodes }}
              >
                <ForceRenderContext.Provider
                  value={{ setForceRender, forceRender }}
                >
                  <DndProvider backend={HTML5Backend}>
                    <Editable
                      //avoid cursor blinking when clicking between nodes
                      onMouseDown={(e: any) => {
                        const target = e.target;
                        if (target.classList.contains('contents')) {
                          e.preventDefault();
                        }
                      }}
                      onDrag={(e) => {
                        e.preventDefault();
                      }}
                      onDrop={(e) => {
                        e.preventDefault();
                      }}
                      onKeyDown={onKeyDown}
                      onKeyUp={onKeyUp}
                      renderElement={renderNode}
                      renderLeaf={renderLeaf}
                    />
                  </DndProvider>
                </ForceRenderContext.Provider>
              </PlaceholderNodeContext.Provider>
            </div>
            <TwoAccordionContainer
              accordion1Props={componentHtmlAccordionProps}
              accordion2Props={componentPreviewAccordionProps}
              className={`${classes.editorRight} ${classes.editorContainer}`}
            />
          </Slate>
        </section>
      )}
      {viewComponentSchema && (
        <ComponentConfigs loadedComponentConfigs={loadedComponentConfigs} />
      )}
      <Toaster />
    </main>
  );
};

export default Main;
