import React, { useEffect, useRef } from 'react';
import {
  layersFocusedNodeClass,
  layersHoveredNodeClass,
  layersHoveredTextClass,
  layersOverlayFocusId,
  layersOverlayHighlightClass,
  layersOverlayHoverId,
  layersReadonlyEditorFocusClass,
  layersReadonlyEditorHoverClass
} from '..';

const DESIRED_PREVIEW_WIDTH = 1400;
const DEFAULT_SCALE = 0.6;
const DIMENSION_REPROCESS_DELAY = 200;
const ACCORDION_TRANSITION_DELAY = 250;

const classes = {
  iframe: `overflow-y-scroll relative border-0 w-[${DESIRED_PREVIEW_WIDTH}px] origin-[0_0] scale-[${DEFAULT_SCALE}]`
};

type ReadonlyHtmlPreviewParams = {
  html: string;
  globalCss: string;
  componentCss: string;
  globalStyles: Array<string>;
  fullscreen: boolean;
  containerRef: any;
  componentHtmlContainer: string;
  accordionResizeState: any;
};

/**
 * Fills a given template with a given container using a templated {{html}}
 * string for entering the html. Only the first occurrence is replaced.
 */
const fillHtmlContainer = (container: string, html: string) => {
  return container.replace(/\{\{html\}\}/, html);
};

const defaultContainer = `\
<div class="row">\
  <div class="rich-text-output">\
    {{html}}\
  </div>\
</div>`;

type ReadonlyEditorClassMatch = {
  classToSearchFor: string;
  classToAddToEditor: string;
};

const readonlyEditorClassesConfig: ReadonlyEditorClassMatch[] = [
  {
    classToSearchFor: layersFocusedNodeClass,
    classToAddToEditor: layersReadonlyEditorFocusClass
  },
  {
    classToSearchFor: layersHoveredNodeClass,
    classToAddToEditor: layersReadonlyEditorHoverClass
  }
];

const bodyHighlightScripts: string = `
function isInViewport(rect) {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

function scrollIfNeeded(element) {
  const rect = element.getBoundingClientRect();
  if (!isInViewport(rect)) {
    window.scrollTo({ left: rect.left, top: rect.top, behavior: "smooth" });
  }
}

/**
 * Moves an overlay over an element
 * @param overlay HTML Element overlay to move
 * @param element HTML Element target to overlay
 */
const _moveOverlay = (overlay, element) => {
  if (!element || !overlay) {
    return;
  }

  // Copy positioning styles
  const rect = element.getBoundingClientRect();
  const { left, right, top, bottom, width, height } = rect;
  const overlayStyles = { left, right, top, bottom };
  //enforce minimum dimensions of 40px x 20px for invisible elements
  overlayStyles.width = Math.max(width, 40);
  overlayStyles.height = Math.max(height, 20);
  // Apply styles from rect to overlay
  Object.entries(overlayStyles).forEach(([property, value]) => {
    overlay.style[property] = value + "px";
  });
};

const getElement = (elements, isFocus) => {
  /**
   * hover process:
   * if element has hoveredTextClass, you want the text node of the node so follow process below
   * focus process:
   * if focused elements length is 1, only the container is focused: no child
   * this means you just wanna focus the text node (if it is a text node)
   * so get the first instance of the text node and then any adjacent formatted text.
   * insert these nodes into a range and get the bounding rect of that for the overlay
   */
  const formattedNodeNames = new Set(["B", "STRONG", "SUP", "#text"]);
  const element = elements.at(-1);
  if (elements.length !== 1) {
    return element;
  }
  //if hover and text node isn't hovered just return the element
  if (!isFocus && !element.classList.contains("${layersHoveredTextClass}")) {
    return element;
  }
  //else try to get the text node
  const childNodes = [...element.childNodes];
  const textNode = childNodes.find((node) =>
    formattedNodeNames.has(node.nodeName)
  );
  if (!textNode) {
    return element;
  }
  const range = document.createRange();
  const textIdx = childNodes.indexOf(textNode);
  const textNodes = [textNode];
  for (let i = textIdx + 1; i < childNodes.length; i++) {
    const node = childNodes[i];
    if (formattedNodeNames.has(node.nodeName)) {
      textNodes.push(node);
    } else break;
  }
  //reverse since insertNode adds at the start
  textNodes.reverse();
  for (let i = 0; i < textNodes.length; i++) {
    if (i === 0) {
      range.selectNode(textNodes[i]);
    } else range.insertNode(textNodes[i]);
  }
  return range;
};

const moveOverlay = (elementClassQuery, overlay, isFocus) => {
  const elements = [...document.querySelectorAll(elementClassQuery)];
  const element = getElement(elements, isFocus);
  _moveOverlay(overlay, element);
};

const getOverlayPositioning = (overlayElement) => ({
  left: parseInt(overlayElement.style.left),
  top: parseInt(overlayElement.style.top),
});

window.addEventListener("load", () => {
  setTimeout(() => {
    const focusOverlay = document.getElementById('${layersOverlayFocusId}');
    const hoverOverlay = document.getElementById('${layersOverlayHoverId}');

    // Move overlays to the correct position
    moveOverlay(".${layersFocusedNodeClass}", focusOverlay, true);
    moveOverlay(".${layersHoveredNodeClass}", hoverOverlay, false);

    const {left: focusLeft, top: focusTop} = getOverlayPositioning(focusOverlay);
    const {left: hoverLeft, top: hoverTop} = getOverlayPositioning(hoverOverlay);

    // if both are in the same spot, give priority to focus by hiding hover
    if (focusLeft === hoverLeft && focusTop === hoverTop) {
      hoverOverlay.style.display = "none";
    }
    
    const focusInUse = focusOverlay.scrollHeight > 0;
    const hoverInUse = hoverOverlay.scrollHeight > 0;
    // if only focus, jump to focus else jump to hover
    if (!hoverInUse && focusInUse) {
      scrollIfNeeded(focusOverlay);
    } else if (hoverInUse) {
      scrollIfNeeded(hoverOverlay);
    }
  });
});
`;

const ReadonlyHtmlPreview = (props: ReadonlyHtmlPreviewParams) => {
  const {
    html = '',
    globalCss = '',
    componentCss = '',
    globalStyles = [],
    fullscreen = false,
    containerRef = null,
    componentHtmlContainer = '',
    accordionResizeState = true
  } = props;

  const iframeRef = useRef(null);
  const iframeRef2 = useRef(null);

  // Any global styles such as those from Epson
  const linkTagsStr = globalStyles
    .map(
      (styleUrl: String) =>
        `<link rel="stylesheet" type="text/css" media="all" href="${styleUrl}" />`
    )
    .join('');

  const filledContainer = fillHtmlContainer(
    componentHtmlContainer || defaultContainer,
    html
  );

  // Straight-forward string comparisons as opposed to node-checking
  const htmlhasString = (html: string, str: string) => html.includes(str);

  const getBodyHighlightClassNames = (html: string) => {
    return readonlyEditorClassesConfig.reduce((acc, next) => {
      const { classToSearchFor, classToAddToEditor } = next;

      if (htmlhasString(html, classToSearchFor)) {
        return `${acc} ${classToAddToEditor}`;
      }
      return acc;
    }, '');
  };

  const bodyClassNames = getBodyHighlightClassNames(html);
  const highlightScripts = bodyHighlightScripts;

  // Organize iframe CSS & HTML
  const iframeContent = `
  <head>
    ${linkTagsStr}
    <style>${globalCss}</style>
    <style>${componentCss}</style>
  </head>
  <body class="${bodyClassNames}">
    ${filledContainer}
    <div id="${layersOverlayFocusId}" class="${layersOverlayHighlightClass}"></div>
    <div id="${layersOverlayHoverId}" class="${layersOverlayHighlightClass}"></div>
    <script>${highlightScripts}</script>
  </body>`;

  const getIframeElement = (iframeRef: any): HTMLIFrameElement | null => {
    if (!iframeRef.current) {
      return null;
    }

    const iframeElement = iframeRef.current as HTMLIFrameElement;
    if (!iframeElement.contentWindow) {
      return null;
    }

    return iframeElement;
  };

  const adjustBothFrames = () => {
    adjustDimensionsAndScale(getIframeElement(iframeRef));
    adjustDimensionsAndScale(getIframeElement(iframeRef2));
  };
  // Reprocess dimensions a few milliseconds after resizing the window
  let previousTimeoutId: any = -1;
  const delayReprocessingIframeDimensions = () => {
    clearTimeout(previousTimeoutId);
    previousTimeoutId = setTimeout(adjustBothFrames, DIMENSION_REPROCESS_DELAY);
  };
  const initResizeListener = () => {
    // Initial dimensions
    adjustBothFrames();
    window.addEventListener('resize', delayReprocessingIframeDimensions);
    return () => {
      window.removeEventListener('resize', delayReprocessingIframeDimensions);
    };
  };

  const adjustDimensionsAndScale = React.useCallback(
    (iframeElement: any) => {
      const containerRefCurrent = containerRef.current;
      if (!iframeElement || !containerRefCurrent) return;
      if (!fullscreen) {
        containerRefCurrent.style.top = '0';
        containerRefCurrent.style.position = 'relative';
        containerRefCurrent.style.left = '0';
        containerRefCurrent.style.width = '100%';
        containerRefCurrent.style.height = '100%';
        const containerWidth = containerRefCurrent.clientWidth;
        //find the scale that lets iframe stay at 1400 but fill out container
        const desiredScale = containerWidth / DESIRED_PREVIEW_WIDTH;
        const region = containerRefCurrent.closest('.accordionContent');
        const containerHeight = region?.getBoundingClientRect()?.height;
        iframeElement.style.transform = `scale(${desiredScale})`;
        iframeElement.style.width = `${DESIRED_PREVIEW_WIDTH}px`;
        iframeElement.style.height = `${containerHeight / desiredScale}px`;
      } else {
        containerRefCurrent.style.position = 'fixed';
        containerRefCurrent.style.top = '0';
        containerRefCurrent.style.left = '0';
        containerRefCurrent.style.width = '100vw';
        containerRefCurrent.style.height = '100vh';
        iframeElement.style.transform = `scale(1)`;
        iframeElement.style.width = '90%';
        iframeElement.style.height = '90%';
      }
    },
    [containerRef, fullscreen]
  );
  //eslint-disable-next-line
  useEffect(initResizeListener, [
    adjustDimensionsAndScale,
    adjustBothFrames,
    fullscreen
  ]);

  //when the accordion containing the iframe is resized, adjust the iframes
  useEffect(() => {
    setTimeout(() => {
      adjustBothFrames();
    }, ACCORDION_TRANSITION_DELAY * 2);
    //eslint-disable-next-line
  }, [accordionResizeState, containerRef]);

  /**
   * to avoid blinking, keep a second iframe and swap the 2 when changes are made
   * when the hidden frame is done loading, show that one and hide the other one
   */
  useEffect(() => {
    function swap() {
      const if1 = getIframeElement(iframeRef);
      const if2 = getIframeElement(iframeRef2);
      if (!if1 || !if2) return;
      const hidden = if1.style.display === 'none' ? if1 : if2;
      const shown = if1 === hidden ? if2 : if1;
      shown.onload = null;
      hidden.onload = () => {
        shown.style.display = 'none';
        hidden.style.display = 'block';
      };
      hidden.srcdoc = iframeContent;
    }
    swap();
  });
  return (
    <>
      <iframe
        title="Component Preview"
        ref={iframeRef}
        width={'100%'}
        height={'100%'}
        contentEditable={false}
        className={classes.iframe}
      />
      <iframe
        title="Component Preview"
        ref={iframeRef2}
        width={'100%'}
        height={'100%'}
        contentEditable={false}
        className={classes.iframe}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default ReadonlyHtmlPreview;
