import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfigFile from '../tailwind.config.js';

const tailwindConfig = resolveConfig(tailwindConfigFile);
const typography: any = {
  small: 'text-[10px] font-normal text-black dark:text-white',
  h1: 'text-[30px] font-normal leading-[30px]',
  h2: 'text-[22px] font-normal leading-[30px] text-black dark:text-white',
  h3: 'text-[16px] font-bold leading-[20px]',
  h4: 'text-[14px] font-bold',
  h5: 'text-[12px] font-bold',
  body1: 'text-[14px]',
  input: 'text-[14px]',
  textarea: 'text-[14px] font-mono'
};

const theme = { ...tailwindConfig.theme, typography };
export default theme;
