import escapeHtml from 'escape-html';
import { ReactNode } from 'react';
import { Descendant, Text } from 'slate';
import uniqid from 'uniqid';

import { LayersTextNode } from '..';
import { getHighlightClass } from '../../utils';

export const voidNodes = [
  'area',
  'base',
  'br',
  'col',
  'embed',
  'hr',
  'img',
  'input',
  'link',
  'meta',
  'param',
  'source',
  'track',
  'wbr'
];

export interface RenderableNode {
  children: ReactNode;
  htmlTag?: keyof JSX.IntrinsicElements;

  // UI-only
  hovered?: Boolean;
  focused?: Boolean;

  // Other properties
  [x: string | number | symbol]: any;
}

export function defaultElementRenderer(node: Descendant) {
  if (Text.isText(node)) {
    return renderText(node);
  }

  const {
    htmlTag: Node = 'div',
    attributes,
    unique_id
  } = node as RenderableNode;

  let updatedAttributes = {
    ...attributes
  };

  const highlightClassName = getHighlightClass(node);
  const additionalClassNames = [highlightClassName].join(' ');

  updatedAttributes.className = [
    updatedAttributes.className,
    additionalClassNames
  ]
    .join(' ')
    .trim();

  if (voidNodes.includes(Node)) {
    return <Node key={unique_id} {...updatedAttributes} />;
  }

  const children = (node?.children || []).map((n) =>
    defaultElementRenderer(n as Descendant)
  );

  return (
    <Node key={unique_id} {...updatedAttributes}>
      {children}
    </Node>
  );
}

// Be sure to call Text.isText(node) prior to calling
// this function
export function renderText(node: LayersTextNode) {
  const { bold, strong, text, attributes = {}, sup } = node;
  let escapedHtmlText = escapeHtml(text) as any;
  if (bold) {
    escapedHtmlText = (
      <b key={uniqid()} {...attributes}>
        {escapedHtmlText}
      </b>
    );
  }
  // Distinction preserved to also preserve the tag
  else if (strong) {
    escapedHtmlText = (
      <strong key={uniqid()} {...attributes}>
        {escapedHtmlText}
      </strong>
    );
  }
  if (sup) {
    escapedHtmlText = (
      <sup key={uniqid()} {...attributes}>
        {escapedHtmlText}
      </sup>
    );
  }
  // return renderStaticNode(escapedHtmlText);
  return escapedHtmlText;
}
