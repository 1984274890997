const classes = {
  root: 'p-0 [&_h3]:m-0 [&_h4]:m-0 [&_h5]:m-0 [&_>_.contents_>_div:not(.addNodeDivider)]:m-0 [&_>_.contents_>_div:not(.addNodeDivider)]:border-l [&_>_.contents_>_div:not(.addNodeDivider)]:border-l-primaryGrey'
};

export const Component = (props: any) => {
  const { children, attributes } = props;
  return (
    <div {...attributes} className={classes.root}>
      <div className="contents">{children}</div>
    </div>
  );
};

export default Component;
