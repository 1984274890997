import { useRef } from 'react';
import { useSlateStatic } from 'slate-react';
import AddNodeDividerWrapper from '../../../components/addNodeDivider/addNodeDividerWrapper';
import {
  getDisplayName,
  getPath,
  isAncestorPlaceholder
} from '../../../helpers';
import { getHighlightClass } from '../../../utils';
import { useElementHoverListeners } from '../partials';
import ElementHeader from './element-header';

const classes = {
  root: 'bg-white dark:bg-black p-[10px] mt-0 mr-[5px] mb-0 ml-[10px] border border-primaryGrey border-l-[5px] border-l-primaryGrey',
  leafContainer:
    'border border-[#333333] dark:border-white w-[calc(100%-15px]) p-[5px] bg-white min-h-[15px]',
  rte: 'border border-[#333333] dark:border-white min-h-[30px] resize-y overflow-auto',
  elementAttribute:
    '[&_h5]:mt-[5px] [&_input]:w-[calc(100%-15px)] [&_input]:min-h-[15px] [&_input]:p-[5px] [&_input]:border [&_input]:border-[#333333] [&_input]:dark:border-white',
  placeholder:
    'border border-primaryGrey/50 border-l-[5px] border-l-primaryGrey/50 [&_>_div:not(.header)]:opacity-50 [&_h4]:opacity-50'
};

//lite version of inputElement to use for textNodes that aren't leaves
const TextElement = (props: any) => {
  const { children, rte, containerNode, textNode } = props;
  //extra listeners to make it known only text contents of element are hovered
  const editor = useSlateStatic();
  const uneditable =
    !!containerNode.placeholder ||
    isAncestorPlaceholder(editor, containerNode as any);
  const ref = useRef(null);
  const path = getPath(editor, textNode) as any;
  //hover is set on text node itself but focus is on parent container
  const highlightClass = getHighlightClass(textNode);
  const parentHightlightClass = getHighlightClass(
    containerNode,
    ref.current,
    editor,
    path
  );
  const classNames = [
    classes.root,
    !uneditable ? highlightClass : '',
    !uneditable ? parentHightlightClass : '',
    uneditable ? classes.placeholder : '',
    'text-element'
  ].join(' ');
  const leafContainerClassNames = [
    classes.leafContainer,
    'leafContainer',
    'field-input',
    uneditable ? 'uneditable' : '',
    !!rte ? 'rte' : '',
    !!rte ? classes.rte : ''
  ].join(' ');
  const { onMouseEnter, onMouseLeave } = useElementHoverListeners(
    editor,
    textNode,
    true
  );
  const displayName = getDisplayName(editor, textNode, path);
  return (
    <AddNodeDividerWrapper element={textNode} path={path}>
      <div
        ref={ref}
        className={classNames}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <ElementHeader className="header" name={`${displayName} text`.trim()} />
        <div data-path={path} tabIndex={0} className={leafContainerClassNames}>
          {children}
        </div>
      </div>
    </AddNodeDividerWrapper>
  );
};

export default TextElement;
