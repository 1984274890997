import { Node } from 'slate';

import { LayersNode, LayersNodeConfig } from '../types';

import loosePropertiesMatch from './loose-properties-match';

/**
 * Enhancement to `Node.matches` to also account
 * for custom attributes
 */
export default function nodeMatches(
  node: LayersNode,
  config: LayersNodeConfig
): Boolean {
  const isExactMatched = Node.matches(node, config.exactMatch);
  const isLooseMatched = loosePropertiesMatch(node, config.looseMatch);
  return isExactMatched && isLooseMatched;
}
