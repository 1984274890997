import { LayersComponentNode } from '../types';

const defaultNode: LayersComponentNode = {
  type: 'component',
  unique_id: 'No component loaded',
  attributes: {
    'data-component-id': 'placeholder'
  },
  children: [{ text: '' }]
};

export default defaultNode;
