import { isEqual } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import React from 'react';
import { useSlateStatic } from 'slate-react';
import { DarkModeContext } from '../context';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../shadcn/components/select';
import { ComponentConfigMap } from '../slatejs';

const classes = {
  root: 'flex flex-row items-center h-[50px]',
  trigger: 'border border-primaryGrey text-black dark:text-white',
  content: 'text-black bg-white ',
  contentDark: '[&_div]:text-white bg-black',
  item: 'hover:cursor-pointer',
  itemDark:
    'hover:cursor-pointer hover:!bg-darkHover focus:!bg-darkHover !text-white'
};

export default function ComponentSelector(props: any) {
  const {  curComponent, componentConfigs: cc = {}, setter, initialTree } = props;
  const componentConfigs: ComponentConfigMap = cc;
  const editor = useSlateStatic();
  const currentComponentName = curComponent?.config?.name || '';
  const componentConfigsByName = Object.entries(componentConfigs).reduce(
    (acc, [_, value]) => {
      acc[value.name] = value;
      return acc;
    },
    {} as any
  );
  const { darkMode } = React.useContext(DarkModeContext) as any;

  const handleOptionSelect = (key: string) => {
    const componentOption = componentConfigsByName[key] as any;
    const newComponentName = componentOption?.name || '';
    const sameComponent = currentComponentName === newComponentName;
    const loadStr = `${sameComponent ? 'Rel' : 'L'}oad`;
    //no component loaded or no current changes
    const noChanges =
      !Object.keys(initialTree).length ||
      isEqual(
        omitDeep(editor.children[0], ['collapsed']),
        omitDeep(initialTree, ['collapsed'])
      );

    let confirmed = false;
    //if changes were made, get confirmation
    if (!noChanges) {
      confirmed = window.confirm(
        `${loadStr} "${newComponentName}" component? Any edits will be lost.`
      );
    }
    if (noChanges || confirmed) {
      setter(componentOption.componentTemplateHtml);
    }
  };

  return (
    <div className={classes.root}>
      {curComponent && (
        <Select
          value={currentComponentName || ''}
          onValueChange={(value) => {
            handleOptionSelect(value);
          }}
        >
          <SelectTrigger className={classes.trigger}>
            <SelectValue placeholder="Select a component" />
          </SelectTrigger>
          <SelectContent
            className={darkMode ? classes.contentDark : classes.content}
          >
            <SelectItem className={'hidden'} value="">
              Select a component
            </SelectItem>
            {Object.entries(componentConfigsByName).map(
              ([key, value]: any, i: number) => (
                <SelectItem
                  className={darkMode ? classes.itemDark : classes.item}
                  key={i}
                  value={key}
                >
                  {value.name}
                </SelectItem>
              )
            )}
          </SelectContent>
        </Select>
      )}
    </div>
  );
}
