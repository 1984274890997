import { createElement } from 'react';
import theme from '../config/theme';



export default function Typography(props: any) {
  const {
    component = 'span',
    variant,
    className = '',
    children,
    ...other
  } = props;
  const variantClass = theme.typography[variant] || '';
  const classNames = [variantClass, className].join(' ');
  return createElement(
    component,
    { className: classNames, ...other },
    children
  );
}
