import { ReactEditor, useSlateStatic } from 'slate-react';

import EditableElementAttribute from './editable-attribute';
import ElementHeader from './element-header';

import { getHighlightClass } from '../../../utils';

import AddNodeDividerWrapper from '../../../components/addNodeDivider/addNodeDividerWrapper';
import AddRemoveNodesBtns from '../../../components/addRemoveNodesBtns';
import { getDisplayName, isAncestorPlaceholder } from '../../../helpers';
import { useElementHoverListeners } from '../partials';

const classes = {
  root: '[&_h5:not(:first-of-type)]:mt-[5px] bg-white dark:bg-black p-[10px] mt-0 mr-[5px] mb-0 ml-[10px] border border-primaryGrey border-l-[5px] border-l-primaryGrey [&_h5]:mt-[5px] [&_input]:w-[calc(100%-15px)] [&_input]:min-h-[15px] [&_input]:p-[5px] [&_input]:border [&_input]:border-[#333333] [&_input]:dark:border-white',
  headerContainer: 'flex flex-row items-center gap-[15px] justify-between m-0',
  placeholder:
    'border border-primaryGrey/50 border-l-[5px] border-l-primaryGrey/50 [&_>_*:not(.node-btns):not(.header)]:opacity-50 [&_>_.header_>_h4]:opacity-50'
};

export const ImageElement = (props: any) => {
  const { element, attributes, children } = props;
  const editor = useSlateStatic();
  const highlightClass = getHighlightClass(element);
  const uneditable =
    !!element.placeholder || isAncestorPlaceholder(editor, element as any);
  const classNames = [
    classes.root,
    !uneditable ? highlightClass : '',
    uneditable ? classes.placeholder : ''
  ].join(' ');

  const path = ReactEditor.findPath(editor, element as any);
  const { onMouseEnter, onMouseLeave } = useElementHoverListeners(
    editor,
    element,
    false
  );
  const displayName = getDisplayName(editor, element, path);
  const headerClassNames = [classes.headerContainer, 'element-header'].join(
    ' '
  );
  return (
    <AddNodeDividerWrapper element={element} path={path}>
      <div
        {...attributes}
        className={classNames}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className={headerClassNames} contentEditable={false}>
          <ElementHeader name={displayName || element.htmlTag} />
          <AddRemoveNodesBtns editor={editor} element={element} />
        </div>
        <EditableElementAttribute
          {...{ element, attrKey: 'src', attrLabel: 'Source', path }}
        />
        <EditableElementAttribute
          {...{ element, attrKey: 'alt', attrLabel: 'Alternate name', path }}
        />
        {children}
      </div>
    </AddNodeDividerWrapper>
  );
};

export default ImageElement;
