import { ReactEditor } from 'slate-react';
import { LayersEditor } from '../../types';
import useElementFocusListeners from './use-element-focus-listeners';
import { Editor } from 'slate';

const useEditableFieldFocusHighlight = (
  editor: LayersEditor,
  node: any,
  selected: boolean
) => {
  const { focused } = node;
  const path = ReactEditor.findPath(editor, node);
  const [, ancestorNodePath] = Editor.parent(editor, path);

  const { onFocus, onBlur } = useElementFocusListeners(
    editor,
    ancestorNodePath
  );

  //if selected, then get the ancestor node (inputElement, image, etc) and focus it
  if (selected) {
    // TODO: unfocus previous node?
    onFocus();
  } else if (!focused) {
    onBlur();
  }
};

export default useEditableFieldFocusHighlight;
