import { useEffect, useRef, useState } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import { isAncestorPlaceholder } from '../../../helpers';
import { handleTab } from '../on-key-down';
import { useElementFocusListeners } from '../partials';
import Typography from '../../../components/typography';
import theme from '../../../config/theme';

/**
 * Editor for HTML attributes.
 * Primarily intended for `src` and `alt`.
 */
const EditableElementAttribute = (props: any) => {
  const { element, attrKey, attrLabel, path } = props;
  const editor = useSlateStatic();
  const { attributes: htmlAttributes } = element;
  const [attrValue, setAttrValue] = useState(htmlAttributes?.[attrKey] || '');

  const { onFocus, onBlur } = useElementFocusListeners(element, path);
  const ref = useRef<HTMLInputElement>(null);

  //when parent element re-renders, update the input value
  useEffect(() => {
    setAttrValue(htmlAttributes?.[attrKey] || '');
  }, [htmlAttributes, attrKey]);

  //handle tabs manually to ensure inputElements are included in the flow
  useEffect(() => {
    const current = ref.current;
    const handleTabWrapper = (e: any) => handleTab(e, current, editor);
    if (!!current) {
      current.addEventListener('keydown', handleTabWrapper);
    }
    return () => {
      if (!!current) {
        current.removeEventListener('keydown', handleTabWrapper);
      }
    };
    //eslint-disable-next-line
  }, []);
  // Event handlers
  const handleChange = (event: any) => {
    const newAttrValue = event.target.value;
    setAttrValue(newAttrValue);
    const enhancedAttributes = {
      attributes: { ...htmlAttributes, [attrKey]: newAttrValue }
    };
    const transformOptions = { at: path };
    Transforms.setNodes(editor, enhancedAttributes, transformOptions);
  };

  const handleFocus = () => {
    onFocus();
  };
  const handleBlur = () => {
    setAttrValue(htmlAttributes[attrKey]);
    onBlur();
  };

  const uneditable =
    !!element.placeholder || isAncestorPlaceholder(editor, element);

  const classNames = [
    theme.typography?.input,
    'field-input',
    uneditable ? 'uneditable' : ''
  ].join(' ');
  return (
    <>
      <Typography variant="h5" contentEditable={false}>
        {attrLabel || attrKey}
      </Typography>
      <input
        ref={ref}
        className={classNames}
        contentEditable={false}
        value={attrValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        disabled={uneditable}
      />
    </>
  );
};

export default EditableElementAttribute;
