import React, { useRef, useState } from 'react';
import { Transforms } from 'slate';
import { useSlateStatic } from 'slate-react';
import uniqid from 'uniqid';
import {
  cloneNode,
  getNode,
  getPath,
  getSameTypeSiblingCluster,
  isAncestorPlaceholder
} from '../../helpers';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../shadcn/components/dropdown';
import { useOutsideClick } from '../../slatejs';
import Droppable from '../droppable';
import { AddNodeBtn } from '../icons';
import { DarkModeContext } from '../../context';

const classes = {
  root: '[&_>_div]:w-full caret-transparent w-full z-[3] [&.sm]:absolute [&.sm]:h-[10px] [&.sm]:top-[-10px] [&.md]:relative [&.md]:h-[15px] [&.lg]:relative [&.lg]:h-[20px] flex items-center !m-0 hover:cursor-default',
  lineBtn: 'w-full flex items-center',
  line: 'flex-grow h-[2px] bg-black dark:bg-white',
  lineContainer: 'h-full flex items-center',
  dottedLine: `w-full after:content-[''] after:block after:w-full after:border after:border-dotted after:border-black dark:after:border-white`,
  figure:
    'm-0 flex justify-center items-center w-[15px] h-[15px] bg-white shadow-roundedBlack rounded-[15px] hover:cursor-pointer [&_img]:w-[10px] [&_img]:h-[10px]',
  menuContentDark: 'bg-black text-white',
  nodeOptionDark: 'hover:cursor-pointer hover:!bg-darkHover !text-white',
  nodeOption: 'hover:cursor-pointer'
};

export default function AddNodeDivider(props: any) {
  const { pathBefore, pathAfter, size } = props;
  const [hover, setHover] = useState(false);
  const editor = useSlateStatic();
  const nodeBefore = getNode(editor, pathBefore) as any;
  const nodeAfter = getNode(editor, pathAfter) as any;
  const allowAddition = (node: any) => {
    if (!node || node.placeholder) {
      return false;
    }
    const adjSameTypeSiblings = getSameTypeSiblingCluster(editor, node) as any;
    //exclude nodes that are "placeholder" AKA ignored by the editor
    const nonPlaceholderSameTypeSiblings = adjSameTypeSiblings.filter(
      (s: any) => !s.placeholder
    );
    const min = node?.config?.constraints?.min || 0;
    const max = node?.config?.constraints?.max || 0;
    const noLimit = (min === 0 && max === 0) || max < min;
    return nonPlaceholderSameTypeSiblings.length < max || noLimit;
  };
  const nodeOptions: any[] = [];
  if (allowAddition(nodeBefore) && !!nodeBefore?.config?.name) {
    nodeOptions.push(nodeBefore);
  }
  if (
    allowAddition(nodeAfter) &&
    !!nodeAfter?.config?.name &&
    nodeBefore?.config?.name !== nodeAfter?.config?.name
  ) {
    nodeOptions.push(nodeAfter);
  }

  const createNode = (node: any) => {
    const { unique_id, ...other } = node;
    const newNode = cloneNode(editor, other, true);
    newNode.unique_id = uniqid(`${newNode.htmlTag}-`);
    const nodePath = getPath(editor, node);
    if (!!nodePath) {
      Transforms.insertNodes(editor, newNode as any, {
        at: pathAfter
      });
    }
  };
  const classNames = [classes.root, 'addNodeDivider', size].join(' ');
  const menuContentRef = useRef(null);
  useOutsideClick(menuContentRef, () => setHover(false));
  const showLine =
    nodeOptions.length > 0 &&
    !isAncestorPlaceholder(editor, nodeBefore) &&
    !isAncestorPlaceholder(editor, nodeAfter);
  const showDottedLine = showLine && !hover;
  const showButtonLine = showLine && hover;
  const { darkMode } = React.useContext(DarkModeContext) as any;
  return (
    <Droppable
      nodeBefore={nodeBefore}
      nodeAfter={nodeAfter}
      className={classNames}
      path={pathAfter}
    >
      <div
        contentEditable={false}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(!!menuContentRef.current)}
        className={classes.lineContainer}
      >
        {showDottedLine && <div className={classes.dottedLine}></div>}
        {showButtonLine && (
          <>
            <div className={classes.lineBtn}>
              <div className={classes.line}></div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <figure className={classes.figure}>
                    <AddNodeBtn />
                  </figure>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  ref={menuContentRef}
                  className={darkMode ? classes.menuContentDark : ''}
                >
                  <DropdownMenuGroup>
                    {nodeOptions.map((node: any, i: number) => (
                      <DropdownMenuItem
                        className={
                          darkMode ? classes.nodeOptionDark : classes.nodeOption
                        }
                        key={i}
                        onClick={() => {
                          setHover(false);
                          createNode(node);
                        }}
                      >
                        {node?.config?.name}
                      </DropdownMenuItem>
                    ))}
                  </DropdownMenuGroup>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </>
        )}
      </div>
    </Droppable>
  );
}
