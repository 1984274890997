import { useEffect, useRef, useState } from 'react';
import { showToastSuccess } from '../helpers';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger
} from '../shadcn/components/accordion';
import Typography from './typography';

const ACCORDION_BOTTOM_MARGIN = 70;
const COLLAPSED_ACCORDION_HEIGHT = 40;
const COLLAPSED_ACCORDION_HEIGHT_CLASS = '[&>div>h3]:max-h-[40px]';
const ACCORDION_TRANSITION_DURATION = 250;

const classes = {
  dummyFull: 'w-full h-[90%] min-h-[90%] max-h-[90%] invisible absolute',
  dummyHalf: 'w-full h-[50%] min-h-[50%] max-h-[50%] invisible absolute',
  root: 'relative flex flex-col',
  accordion: `w-full max-h-[90%] ${COLLAPSED_ACCORDION_HEIGHT_CLASS}`,
  trigger: 'bg-primaryGrey py-[10px] px-[10px] text-white hover:no-underline',
  triggerContents: 'w-full flex items-center gap-[10px] [&_img]:w-[20px]',
  content: 'border-[1px] border-primaryGrey'
};

type accordionContainerProps = {
  className: string;
  accordion1Props: (...args: any) => { children: any; states?: any };
  accordion2Props: (...args: any) => { children: any; states?: any };
};

export default function TwoAccordionContainer(props: accordionContainerProps) {
  const { className, accordion1Props, accordion2Props } = props;
  const [expandedState, setExpandedState] = useState<any>({
    accordion1Expanded: false,
    accordion2Expanded: true,
    updatedRecently: false,
    clicked: false
  });
  const classNames = [className, classes.root].join(' ');
  const accordion1Ref = useRef<HTMLDivElement>(null);
  const accordion2Ref = useRef<HTMLDivElement>(null);
  const dummyFullRef = useRef<HTMLDivElement>(null);
  const dummyHalfRef = useRef<HTMLDivElement>(null);
  const [dummyFullHeight, setDummyFullHeight] = useState(0);
  const [dummyHalfHeight, setDummyHalfHeight] = useState(0);
  const [accordion1Height, setAccordion1Height] = useState(0);
  /**
   * expanded accordions don't care about the max height of their container
   * so to make sure our accordions don't overflow, we need to set the max height
   * we use a dummy at full height and half height to determine what to set the max height to
   */

  useEffect(() => {
    if (!!dummyFullRef.current) {
      setDummyFullHeight(dummyFullRef.current.clientHeight);
    }
    if (!!dummyHalfRef.current) {
      setDummyHalfHeight(dummyHalfRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if (!expandedState.updatedRecently) return;
    let timeoutManager: any;
    clearTimeout(timeoutManager);
    timeoutManager = setTimeout(() => {
      if (!!dummyFullRef.current) {
        setDummyFullHeight(dummyFullRef.current.clientHeight);
      }
      if (!!dummyHalfRef.current) {
        setDummyHalfHeight(dummyHalfRef.current.clientHeight);
      }
      //when expanded state changes, take note of what the new height of accordion1 is
      if (!!accordion1Ref.current) {
        if (expandedState.updatedRecently) {
          if (expandedState.accordion1Expanded) {
            if (expandedState.accordion2Expanded) {
              setAccordion1Height(dummyHalfHeight - 15);
            } else {
              setAccordion1Height(dummyFullHeight);
            }
          } else {
            setAccordion1Height(
              accordion1Ref.current.getBoundingClientRect().height
            );
          }
        }
      }
      setExpandedState({
        ...expandedState,
        updatedRecently: false
      });
    }, ACCORDION_TRANSITION_DURATION);
    // eslint-disable-next-line
  }, [expandedState]);

  useEffect(() => {
    const accordion1RefCurrent = accordion1Ref.current;
    let drag = false;
    let down = false;
    const handleMouseDown = () => {
      drag = false;
      down = true;
    };
    const handleMouseMove = () => {
      drag = down;
    };
    const handleEndDrag = () => {
      if (!drag || !down) return;
      drag = false;
      down = false;
      setExpandedState({
        ...expandedState,
        updatedRecently: false
      });
      if (!!accordion1RefCurrent) {
        setAccordion1Height(
          accordion1RefCurrent.getBoundingClientRect().height
        );
      }
    };

    if (!!accordion1RefCurrent) {
      accordion1RefCurrent.addEventListener('mousedown', handleMouseDown);
      accordion1RefCurrent.addEventListener('mousemove', handleMouseMove);
      const main = accordion1RefCurrent.closest('main');
      if (!!main) {
        main.addEventListener('mouseup', handleEndDrag);
      }
    }
    return () => {
      if (!!accordion1RefCurrent) {
        accordion1RefCurrent.removeEventListener('mousedown', handleMouseDown);
        accordion1RefCurrent.removeEventListener('mousemove', handleMouseMove);
        const main = accordion1RefCurrent.closest('main');
        if (!!main) {
          main.removeEventListener('mouseup', handleEndDrag);
        }
      }
    };
    //eslint-disable-next-line
  }, [accordion1Ref.current, expandedState]);

  const handleChange = (targetAccordionExpandedKey: string) => {
    const newExpandedState = {
      ...expandedState,
      updatedRecently: true,
      [targetAccordionExpandedKey]: !expandedState[targetAccordionExpandedKey]
    };
    setExpandedState(newExpandedState);
  };
  const handleAccordion1 = () => handleChange('accordion1Expanded');
  const handleAccordion2 = () => handleChange('accordion2Expanded');
  const getExpandedClass = (expanded: boolean) =>
    expanded ? 'expanded' : 'collapsed';
  const { accordion1Expanded, accordion2Expanded } = expandedState as any;
  const bothExpanded = !!accordion1Expanded && !!accordion2Expanded;
  const topExpanded = !!accordion1Expanded && !accordion2Expanded;
  //get the height to use for accordion1 based on what's expanded
  const getHeightToUseForAccordion1 = () => {
    let height = 0;
    if (bothExpanded) {
      height = dummyHalfHeight;
    } else if (topExpanded) {
      height = dummyFullHeight;
    }
    return height - ACCORDION_BOTTOM_MARGIN;
  };
  const { children: accordion1Children, states: accordion1States } =
    accordion1Props(
      dummyFullHeight - ACCORDION_BOTTOM_MARGIN,
      getHeightToUseForAccordion1(),
      expandedState.updatedRecently
    );
  const [componentHtml, setComponentHtml] = accordion1States.componentHtml;
  const { children: accordion2Children, states: accordion2States } =
    accordion2Props(expandedState);
  const [fullscreenPreview, setFullscreenPreview] = accordion2States.fullscreen;
  const copyHtml = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.writeText(componentHtml);
    showToastSuccess('Copied component html to clipboard');
  };
  const pasteHtml = (e: any) => {
    e.stopPropagation();
    navigator.clipboard.readText().then((read) => {
      setComponentHtml(read);
    });
  };
  return (
    <div className={classNames}>
      <Accordion
        type="single"
        collapsible
        ref={dummyFullRef}
        className={classes.dummyFull}
      >
        <AccordionItem value="">
          <AccordionTrigger></AccordionTrigger>
          <AccordionContent></AccordionContent>
          <AccordionItem value=""></AccordionItem>
        </AccordionItem>
      </Accordion>
      <Accordion
        type="single"
        collapsible
        ref={dummyHalfRef}
        className={classes.dummyHalf}
      >
        <AccordionItem value="item-1">
          <AccordionTrigger></AccordionTrigger>
          <AccordionContent></AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion
        type="single"
        collapsible
        ref={accordion1Ref}
        className={`${classes.accordion} 
        ${getExpandedClass(accordion1Expanded)}
        `}
        value={expandedState.accordion1Expanded.toString()}
      >
        <AccordionItem value={'true'}>
          <AccordionTrigger
            className={classes.trigger}
            onClick={(e) => {
              e.preventDefault();
              handleAccordion1();
            }}
          >
            <div className={classes.triggerContents}>
              <Typography variant="h4">Component HTML</Typography>
              <img
                src="/icons/copyWhite.svg"
                alt="Copy component html to clipboard"
                title="Copy component html to clipboard"
                onClick={copyHtml}
              />
              <img
                src="/icons/pasteWhite.svg"
                alt="Paste text from clipboard"
                title="Paste text from clipboard"
                onClick={pasteHtml}
              />
            </div>
          </AccordionTrigger>
          <AccordionContent className={classes.content}>
            {accordion1Children}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Accordion
        value={expandedState.accordion2Expanded.toString()}
        type="single"
        collapsible
        className={`${classes.accordion} 
        ${getExpandedClass(accordion2Expanded)}
        `}
        ref={accordion2Ref}
      >
        <AccordionItem value="true">
          <AccordionTrigger
            className={classes.trigger}
            onClick={(e) => {
              e.preventDefault();
              handleAccordion2();
            }}
          >
            <div className={classes.triggerContents}>
              <Typography variant="h4">Component Preview</Typography>
              <img
                src="/icons/expandWhite.svg"
                alt="Expand component preview"
                title="Expand component preview"
                onClick={(e) => {
                  e.stopPropagation();
                  setFullscreenPreview(!fullscreenPreview);
                }}
              />
            </div>
          </AccordionTrigger>
          <AccordionContent
            className={`${classes.content} accordionContent`}
            style={{
              height: `calc(${dummyFullHeight}px - ${Math.max(
                accordion1Height,
                COLLAPSED_ACCORDION_HEIGHT
              )}px - 25px)`
            }}
          >
            {accordion2Children}
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
}
