import { Directus } from '@directus/sdk';

import {
  ComponentConfigMap,
  ConfigConstraints,
  EditableProps,
  ExactMatch,
  LayersNodeConfig,
  LooseMatch
} from '../slatejs/types';

type CustomTypes = {
  tag_editor_config_node: LayersNodeConfig;
  tag_editor_exact_match: ExactMatch;
  tag_editor_loose_match: LooseMatch;
  tag_editor_constraints: ConfigConstraints;
  tag_editor_editable_props: EditableProps;
};

const API_URL = process.env.REACT_APP_API_URL || '';

const directus = new Directus<CustomTypes>(API_URL);

/**
 * Remove unnecessary properties from exactMatch
 */
const sanitizeExactMatch = (exactMatch: any): ExactMatch => {
  const { type, htmlTag, other_props } = exactMatch || {};
  const { isDummy } = other_props || {};
  let obj: any = {
    type,
    htmlTag,
    children: [],
    // include other properties such as isDummy
    ...other_props
  };

  if (isDummy === null) {
    obj.isDummy = undefined;
  } else if (obj.isDummy) {
    obj.isDummy = true;
  }
  return obj;
};

/**
 * Remove unnecessary properties from looseMatch
 */
const sanitizeLooseMatch = (looseMatch: any): LooseMatch | undefined => {
  if (!looseMatch) {
    return undefined;
  }

  const { attributes = {} } = looseMatch || {};

  return {
    attributes
  };
};

/**
 * Remove unnecessary properties from looseMatch
 */
const sanitizeConstraints = (
  constraints: any
): ConfigConstraints | undefined => {
  if (!constraints) {
    return undefined;
  }
  const { min = undefined, max = undefined, rte = false } = constraints || {};

  return {
    min,
    max,
    rte
  };
};

// function createLayersNodeConfig(node: any) :LayersNodeConfig {

// }

/**
 * Adjusts certain properties to to work with the rest of the system.
 * - ensures `exactMatch.children` to be an empty array
 */
const mapConfigNodes = (
  configNodes: LayersNodeConfig[]
): LayersNodeConfig[] => {
  function mapNode(node: any): LayersNodeConfig {
    delete node.date_created;
    delete node.date_updated;
    Object.assign(node, {
      ...node,
      exactMatch: sanitizeExactMatch(node.exactMatch),
      looseMatch: sanitizeLooseMatch(node.looseMatch),
      constraints: sanitizeConstraints(node.constraints),
      editableProps: node.editableProps,
      supportsChildren: node.supportsChildren,
      name: node.name,
      componentCss: node.componentCss,
      componentHtmlContainer: node.componentHtmlContainer,
      componentTemplateHtml: node.componentTemplateHtml || '',
      children: node.children
    });

    return node;
  }

  return configNodes.map((configNode) => {
    return mapNode(configNode);
  });
};

/**
 * Map collection by a key.
 * e.g. by "id": { 1: <item>, 2: <item> }
 */
const mapCollection = (collectionItems: any[], byKey = 'id') => {
  return collectionItems.reduce(
    (acc, next: any) => ({
      ...acc,
      [next[byKey]]: next
    }),
    {}
  );
};

// function getGlobalSettings() {
//   return (directus.items('tag_editor_globals') as any).readByQuery({
//     limit: 1,
//     fields: ['globalCss.directus_files_id']
//   });
// }

function getConfigNodes() {
  return (directus.items('tag_editor_config_node') as any).readByQuery({
    limit: -1,
    fields: [
      'id',
      'name',
      'exactMatch.*',
      'looseMatch.*',
      'constraints.*',
      'editableProps',
      'componentCss',
      'componentHtmlContainer',
      'componentTemplateHtml',
      'supportsChildren',
      'children.related_tag_editor_config_node_id.id'
    ]
  });
}

/**
 * Returns mapped component configurations, which should be ready for Slate.
 */
export function getMappedComponentConfigNodes(): ComponentConfigMap {
  return getConfigNodes().then(({ data }: any) => {
    // Map by id
    const map = mapCollection(data);
    data.forEach((item: any) => {
      item.children = (item.children || [])
        .map((child: any, i: any) => {
          return map[child.related_tag_editor_config_node_id?.id];
        })
        .filter((v: any) => !!v);
    });

    // Map related items
    const mappedNodes = mapConfigNodes(data);

    // Keep only components
    const components = mappedNodes.filter((node) => {
      return node?.exactMatch?.type === 'component';
    });
    // Map component configs by their property:
    // `componentConfig.looseMatch.attributes.['data-component-id']`
    const reducedComponents = components.reduce((acc, componentConfig) => {
      const id = componentConfig?.looseMatch?.attributes?.['data-component-id'];
      if (!id) {
        return acc;
      }

      return {
        ...acc,
        [id]: componentConfig
      };
    }, {});
    return reducedComponents;
  });
}

export default directus;
