import { Label } from '../shadcn/components/label';
import { Switch } from '../shadcn/components/switch';

const classes = {
  root: 'flex items-center space-x-2',
  label: 'hover:cursor-pointer',
  switch:
    'data-[state=checked]:bg-pink data-[state=unchecked]:bg-[#e2e8f0] [&_.thumb]:bg-white'
};

const Toggle = (props: any) => {
  const { label, state, setter } = props;
  const handleChange = () => setter(!state);
  return (
    <div className={classes.root}>
      <Label className={classes.label} htmlFor={label}>
        {label}
      </Label>
      <Switch
        className={classes.switch}
        id={label}
        checked={state}
        onCheckedChange={handleChange}
      />
    </div>
  );
};

export default Toggle;
