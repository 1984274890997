import { getParent, getPath, isAncestorPlaceholder } from '../../../helpers';
import { LayersEditor, LayersTypelessNode } from '../../types';
import useElementAttributeToggle from './use-attribute-toggle';

/**
 * Highlight hovered element
 */
const useElementHoverListeners = (
  editor: LayersEditor,
  node: LayersTypelessNode,
  isTextElement: boolean
) => {
  const path = getPath(editor, node as any);
  const parent = getParent(editor, path as any);
  //get listners for the current element and its parent
  const { toggleOn = () => {}, toggleOff = () => {} } =
    useElementAttributeToggle(node, 'hovered');
  const {
    toggleOn: toggleOnParent = () => {},
    toggleOff: toggleOffParent = () => {}
  } = useElementAttributeToggle(parent as any, 'hovered');
  const {
    toggleOn: toggleOnHoveredText = () => {},
    toggleOff: toggleOffHoveredText = () => {}
  } = useElementAttributeToggle(parent as any, 'hoveredText');
  const ancestorIsPlaceholder = isAncestorPlaceholder(editor, node as any);
  const isPlaceholder = !!node.placeholder;
  return {
    onMouseEnter: () => {
      if (isPlaceholder || ancestorIsPlaceholder) {
        toggleOff();
        toggleOffParent();
        return;
      }
      //toggle on hovered for the current element
      toggleOn();
      //if it's a text element, toggle it on for the parent and hoveredText
      if (isTextElement) {
        toggleOnParent();
        //toggling this on makes it known to not highligtht the parent
        toggleOnHoveredText();
        //otherwise toggle off for the parent to ensure only current element is highlighted
      } else if ((parent as any)?.type !== 'component') {
        toggleOffParent();
      }
    },
    onMouseLeave: () => {
      if (isPlaceholder || ancestorIsPlaceholder) {
        return;
      }
      //toggle off hovered for the current element
      toggleOff();
      //if it's a text element, toggle off hoveredText
      if (isTextElement) {
        toggleOffHoveredText();
      }
      //toggle
      if ((parent as any)?.type !== 'component') {
        toggleOnParent();
      }
    }
  };
};

export default useElementHoverListeners;
