import React, { Dispatch, SetStateAction } from 'react';
import { PlaceholderNodeMap } from '../slatejs';
const ForceRenderContext = React.createContext({});

export type PlaceholderNodeContextSetterType = Dispatch<
  SetStateAction<PlaceholderNodeMap>
>;
export type PlaceholderNodeContextType = {
  placeholderNodes: PlaceholderNodeMap;
  setPlaceholderNodes: PlaceholderNodeContextSetterType;
};

const PlaceholderNodeContext = React.createContext(
  {} as PlaceholderNodeContextType
);

const DarkModeContext = React.createContext({});

export { ForceRenderContext, PlaceholderNodeContext, DarkModeContext };
