import { AccordionItem } from '@radix-ui/react-accordion';
import {
  Accordion,
  AccordionContent,
  AccordionTrigger
} from '../shadcn/components/accordion';
import Typography from './typography';

const classes = {
  root: 'mt-[75px] text-xs overflow-auto [&_strong]:cursor-default [&_.flex]:gap-[5px]',
  header: 'text-base',
  accordion:
    'bg-accordionLight dark:bg-accordionDark border border-primaryGrey',
  trigger: 'bg-primaryGrey p-2.5 text-white',
  content: 'text-black dark:text-white px-2.5 py-5'
};

const ComponentSchemaItem = (props: any) => {
  const { fieldSettings, config } = props;
  const { label, key, tooltip } = fieldSettings;
  return (
    <div className="flex-row" title={tooltip}>
      <Typography variant="h4">{label}:</Typography>
      <pre>{JSON.stringify(config[key], null, 2)}</pre>
    </div>
  );
};

type FieldToStringify = {
  key: string;
  label: string;
  tooltip: string;
};

const fieldsToStringify: FieldToStringify[] = [
  {
    label: 'Constraints',
    key: 'constraints',
    tooltip:
      'How many of a node are allowed to exist (if min/max is 0, there is no limit) and if it supports rich text'
  },
  {
    label: 'EditableProps',
    key: 'editableProps',
    tooltip:
      'Any data attributes that should be able to be edited. ("src" and "alt" for images are enabled by default)'
  },
  {
    label: 'ExactMatch',
    key: 'exactMatch',
    tooltip:
      'Attributes that are used to find the correct config during serialization'
  },
  {
    label: 'LooseMatch',
    key: 'looseMatch',
    tooltip:
      'Additional attributes that are used to find the correct config during deserialization'
  },
  {
    label: 'SupportsChildren',
    key: 'supportsChildren',
    tooltip: 'Whether the node can have children nodees nested underneath'
  }
];

export default function ComponentSchema(props: any) {
  const { loadedComponentConfigs } = props;

  const ComponentConfigAccordion = (props: any) => {
    const { config, idx } = props;
    return (
      <Accordion type="multiple" className={classes.accordion}>
        <AccordionItem value={`item-${idx}`}>
          <AccordionTrigger className={classes.trigger}>
            <Typography variant="h4">{config.name}</Typography>
          </AccordionTrigger>
          <AccordionContent className={classes.content}>
            <div className="flex flex-col">
              {fieldsToStringify.map(
                (fieldSettings: FieldToStringify, i: number) => (
                  <ComponentSchemaItem
                    key={i}
                    fieldSettings={fieldSettings}
                    config={config}
                  />
                )
              )}
              {config?.children?.length > 0 && (
                <div className="flex flex-col">
                  <strong>Children: </strong>
                  <div className="flex flex-col">
                    {config.children.map((c: any, i: number) => (
                      <ComponentConfigAccordion
                        config={c}
                        key={i}
                        idx={`${idx}-${i}`}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    );
  };

  return (
    <div className={classes.root}>
      {Object.values(loadedComponentConfigs).map(
        (componentConfig: any, i: number) => (
          <ComponentConfigAccordion config={componentConfig} key={i} idx={i} />
        )
      )}
    </div>
  );
}
