import { LayersNode, LooseMatch } from '../types';

/**
 * Converts a classname string to array by splitting by spaces, and
 * removing empty strings
 */
function classNameToArray(className: string): Array<string> {
  return (
    (className || '')
      // Simply split by space
      .split(' ')
      //And remove empty strings
      .filter((v) => !!v)
  );
}

/**
 * Performs custom matching for certain properties, such as `className` and
 * `data-component-id`
 */
export default function loosePropertiesMatch(
  node: LayersNode,
  looseMatch: LooseMatch | undefined
): Boolean {
  const { attributes = {} } = looseMatch || {};
  const { className = '', 'data-component-id': componentId = '' } = attributes || {};

  const { attributes: nodeAttributes = {} } = node;
  const { className: nodeClassName = '', 'data-component-id': nodeComponentId = '' } =
    nodeAttributes;

  // Class not matched
  const expectedClasses = new Set(classNameToArray(className || ''));
  const nodeClasses = new Set(classNameToArray(nodeClassName || ''));

  const classesMatch =
    expectedClasses.size === nodeClasses.size &&
    [...nodeClasses].every((nodeClass) => expectedClasses.has(nodeClass));

  if (!classesMatch) {
    return false;
  }

  if (componentId && nodeComponentId !== componentId) {
    return false;
  }

  return true;
}
