import { ReactElement } from 'react';
import ReactDomServer from 'react-dom/server';
import { unescapeEntities, unescapeExtraAmp } from '../helpers/escapeEntities';

import NodeRenderer from '../slatejs/handlers/default-fields-renderer';
import LeafRenderer from '../slatejs/handlers/leaf-renderer';

import { Descendant, Path } from 'slate';
import {
  serializeHtml as _serializeHtml,
  deserializeHtml
} from '../slatejs/handlers';

import {
  ComponentList,
  LayersEditor,
  LayersNode,
  LayersTypelessNode
} from '../slatejs/types';

import {
  defaultNode,
  layersFocusedNodeClass,
  layersHoveredNodeClass,
  layersHoveredTextClass
} from '../slatejs';

import findParentWithAttribute from './find-parent-with-attribute';

const renderStaticNode = (node: ReactElement) => {
  //all entities will have their & escaped, so unescape it back before returning
  const staticMarkup = unescapeExtraAmp(
    unescapeEntities(ReactDomServer.renderToStaticMarkup(node))
  );
  return staticMarkup;
};

const deserializeHtmlString = (
  html: string,
  loadedComponentConfig: ComponentList
) => {
  const deserializeNode = (el: HTMLElement, markAttributes = {}) => {
    if (!el) {
      return defaultNode;
    }
    const component_id = el.getAttribute('data-component-id') as string;
    const config = loadedComponentConfig[component_id];
    const delegatedDeserializer = deserializeHtml(config);

    return delegatedDeserializer(el, markAttributes);
  };

  const tmp_el: HTMLElement = document.createElement('fragment');
  tmp_el.innerHTML = html;
  const deserialized = deserializeNode(tmp_el.children[0] as HTMLElement);
  return deserialized;
};

const serializeHtml = (tree: LayersNode) => {
  return _serializeHtml(tree as Descendant);
};

/**
 * Determines the appropriate highlight class for a given element
 * @param element an editor node
 * @param textElementRefCurrent the ref of the text element
 * if supplied, it means text element is calling this with its container as the element param
 * @returns the appropriate highlight class
 */
const getHighlightClass = (
  element: LayersTypelessNode,
  textElementRefCurrent?: any,
  editor?: LayersEditor,
  path?: Path
): string => {
  const { hovered, focused, hoveredText } = element;
  const classNames = [];
  if (!focused && !hovered) {
    // No highlight class
    return '';
  }
  if (focused) {
    //if container is focused and ref is current focused element, add that class to text element
    if (!!textElementRefCurrent) {
      const { selection } = editor as any;
      const selectedPath = selection?.anchor?.path || [];
      const textElementFocused = Path.equals(path as any, selectedPath);
      if (textElementFocused) {
        classNames.push(layersFocusedNodeClass);
      }
      //otherwise just add that class if element is focused
    } else {
      classNames.push(layersFocusedNodeClass);
    }
  }
  //if hovered and not dealing with text element
  if (hovered && !textElementRefCurrent) {
    classNames.push(layersHoveredNodeClass);
  }
  //if container has hoveredText set, add additional class to make that known
  if (hoveredText && !textElementRefCurrent) {
    classNames.push(layersHoveredTextClass);
  }
  return classNames.join(' ');
};

const renderComponentChildren = NodeRenderer;

export { default as kebabCaseToCamel } from './kebab-case-to-camel';
export {
  LeafRenderer,
  deserializeHtmlString,
  findParentWithAttribute,
  getHighlightClass,
  renderComponentChildren,
  renderStaticNode,
  serializeHtml
};
