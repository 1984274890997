import { styleStringToObject } from './style-string-to-object';
/**
 * Maps an element's attributes for use with React,
 * particularly by converting `style` to a map, and
 * renaming `class` to `className`.
 */
export default function mapHtmlElementAttributes(el: HTMLElement) {
  return (
    [...el.attributes]
      // Remove empty attributes
      .filter((v) => !!v?.name)
      .reduce((acc, attr) => {
        let { value, name } = attr;
        // Convert 'style' to object
        if (name === 'style') {
          value = styleStringToObject(value) as any;
        }
        // use 'className'
        else if (name === 'class') {
          name = 'className';
        }
        return {
          ...acc,
          [name]: value
        };
      }, {})
  );
}
