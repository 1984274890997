import { HistoryEditor } from 'slate-history';
import { useSlateStatic } from 'slate-react';
import { asHistoryEditor } from '../helpers';
import { RedoBtn, UndoBtn } from './icons';

const classes = {
  root: 'relative flex gap-[15px] mt-[20px] mr-[10px] mb-0 ml-0 [&_figure]:m-0',
  icon: 'w-[15px] h-[15px] hover:cursor-pointer',
  disabled: 'opacity-50 [&_img:hover]:cursor-default hover:cursor-default'
};

export default function UndoRedoBtns(props: any) {
  const { darkMode } = props;
  const editor = asHistoryEditor(useSlateStatic());
  const disableUndo = editor.history.undos.length === 0;
  const disableRedo = editor.history.redos.length === 0;
  const getDisabledClass = (condition: boolean) =>
    condition ? classes.disabled : '';
  return (
    <div className={classes.root}>
      <figure
        className={getDisabledClass(disableUndo)}
        onClick={() => {
          if (disableUndo) return;
          HistoryEditor.undo(editor);
        }}
      >
        <UndoBtn darkMode={darkMode} className={classes.icon} />
      </figure>
      <figure
        className={getDisabledClass(disableRedo)}
        onClick={() => {
          if (disableRedo) return;
          HistoryEditor.redo(editor);
        }}
      >
        <RedoBtn darkMode={darkMode} className={classes.icon} />
      </figure>
    </div>
  );
}
