import { useSlateStatic } from 'slate-react';
import { getNextPath, getParent } from '../../helpers';
import AddNodeDivider from './addNodeDivider';

export default function AddNodeDividerWrapper(props: any) {
  const { element: node, path, children } = props;
  const editor = useSlateStatic();
  const isFirstChild = path.at(-1) === 0;
  const parent = getParent(editor, path) as any;
  const isChildOfComponent = parent.type === 'component';
  const isFirstChildOfComponent =
    isChildOfComponent && parent.children[0] === node;
  const nodeType = node?.type;
  const largeSizeSet = new Set(['container', 'dummy']);
  const getSize = (beforeChildren: boolean) => {
    let size = 'md';
    if (largeSizeSet.has(nodeType)) {
      size = 'lg';
    }
    if (isFirstChildOfComponent && beforeChildren) {
      size = 'sm';
    }
    return size;
  };
  return (
    <>
      {isFirstChild && (
        <AddNodeDivider
          pathBefore={null}
          pathAfter={path}
          size={getSize(true)}
        />
      )}
      {children}
      <AddNodeDivider
        pathBefore={path}
        pathAfter={getNextPath(path)}
        size={getSize(false)}
      />
    </>
  );
}
