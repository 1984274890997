import { useEffect, useState } from 'react';
import Main from './components/main';
import { DarkModeContext } from './context';

const App = () => {
  const [darkMode, setDarkModeState] = useState(true);
  const setDarkMode = (value: boolean) => {
    setDarkModeState(value);
    localStorage.setItem('darkMode', `${value}`);
  };

  // Set initial dark mode state
  useEffect(() => {
    const osPrefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches;
    const userPrefersDark = localStorage.getItem('darkMode') === 'true';
    setDarkMode(osPrefersDark && userPrefersDark);
    function handleThemeChange(event: any) {
      setDarkMode(event.matches);
    }
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', handleThemeChange);
    return () =>
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .removeEventListener('change', handleThemeChange);
  }, []);

  return (
    <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      <Main />
    </DarkModeContext.Provider>
  );
};

export default App;
