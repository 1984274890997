import Typography from '../../../components/typography';

const ElementHeader = (props: any) => {
  const { name = '', elementIndexStr = '', elementPathStr = '' } = props;
  return (
    <Typography
      component="h4"
      variant="h4"
      contentEditable={false}
      title={elementPathStr}
    >
      {name} {!!elementIndexStr && <em>{elementIndexStr}</em>}
    </Typography>
  );
};

export default ElementHeader;
