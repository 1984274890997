import { Editor, Location, Path, Transforms } from 'slate';
import { areSiblingLeaves } from '../../helpers';
import { LayersEditor } from '../types';

const LEFT = 37;
const RIGHT = 39;
const UP = 38;
const DOWN = 40;
const upDownKeys = [UP, DOWN];
const upDownKeySet = new Set(upDownKeys);
const directionKeys = [LEFT, RIGHT, UP, DOWN];
const directionKeySet = new Set(directionKeys);

const handleBeforeDelete = (
  event: any,
  editor: LayersEditor,
  curLeafContainer: HTMLElement
) => {
  const { selection } = editor;
  if (!selection || !curLeafContainer) {
    return;
  }
  //find which leaves don't have any text content anymore
  const [start, end] = Editor.edges(editor, selection);
  const curLeaves = [...curLeafContainer.children] || [];
  //before serialization, leaf will be empty with this attribute
  const curFullLeaves = curLeaves.filter(
    (l) => !l.querySelector("span[data-slate-length='0']")
  );
  //if the inputElement component is empty, all children will be empty leaves
  const allEmptyLeaves = curFullLeaves.length === 0;
  //if cursor is at the start of current element, avoid backspacing element before it
  if (start.offset === 0 && end.offset === 0 && allEmptyLeaves) {
    event.preventDefault();
  }
  //if selection spans multiple fields, don't allow backspace
  const startPath = start.path;
  const endPath = end.path;
  if (
    //not the same leaves
    !Path.equals(startPath, endPath) &&
    //not sibling leaves
    !areSiblingLeaves(startPath, endPath)
  ) {
    event.preventDefault();
  }

  // The selection only spans one node, so allow default behavior.
  if (startPath.length === endPath.length) {
    return;
  }
  // Prevent the default behavior of deleting the node.
  event.preventDefault();
};

/**
 * trying to restrict arrow keys from leaving current field
 * for left and right we can keep them contained based on length of text in the field and offsets
 * we can't stop up and down from leaving though so use keyUp to handle that
 **/
const handleBeforeArrow = (
  event: any,
  editor: LayersEditor,
  curLeafContainer: HTMLElement
) => {
  const { selection } = editor;
  if (!selection || !curLeafContainer) {
    return;
  }
  const { keyCode } = event;
  const [start, end] = Editor.edges(editor, selection);
  const keyConditionMap: any = {
    //if at the beginning, don't go back
    [LEFT]: start.offset === 0 && end.offset === 0,
    //if at the end of text don't go forward
    [RIGHT]:
      start.offset === end.offset &&
      start.offset === curLeafContainer.innerText.length
  };
  if (!!keyConditionMap[keyCode]) {
    event.preventDefault();
  }
};

const handleAfterArrow = (event: any, editor: LayersEditor, keyState: any) => {
  const { selection } = editor;
  if (!selection) return;
  const [start] = Editor.edges(editor, selection);
  const keyStatePath = keyState?.target?.path || start.path;
  //if we ended up in a different node, go back immediately
  if (!Path.equals(start.path, keyStatePath)) {
    event.preventDefault();
    Transforms.select(editor, {
      anchor: keyState.target || start,
      focus: keyState.target || start
    });
    return;
  }
};

export const handleOnKeyUp = (
  event: any,
  editor: LayersEditor,
  keyState: any,
  setKeyState: any
) => {
  //up or down
  const upDownHeld = upDownKeys.some((x) => keyState.downKeys.has(x));
  if (upDownKeySet.has(event.keyCode) || upDownHeld) {
    handleAfterArrow(event, editor, keyState);
  }
  //remove key that is no longer down
  const downKeys = keyState.downKeys;
  downKeys.delete(event.keyCode);
  setKeyState({ downKeys, ...keyState });
};

export const handleOnKeyDown = (
  event: KeyboardEvent,
  editor: LayersEditor,
  keyState: any,
  setKeyState: any
) => {
  const selection = window.getSelection();
  const selectionElement = selection?.anchorNode?.parentElement;
  const curLeafContainer = selectionElement?.closest?.('.leafContainer');
  const repeatingUpDown = upDownKeySet.has(event.keyCode) && !!event.repeat;
  const upDownHeld = upDownKeys.some((x) => keyState.downKeys.has(x));
  const upDownHeldOrRepeating = repeatingUpDown || upDownHeld;
  const directionRepeating =
    directionKeySet.has(event.keyCode) && !!event.repeat;
  const directionHeld = directionKeys.some((x) => keyState.downKeys.has(x));
  const directionHeldNotRepeating = !directionRepeating && directionHeld;

  // Prevent removing previous/next elements
  if (event.key === 'Backspace' || event.key === 'Delete') {
    handleBeforeDelete(event, editor, curLeafContainer as HTMLElement);
  }
  // Prevent duplicating fields
  else if (event.key === 'Enter') {
    // TODO: allow for rich-text fields?
    event.preventDefault();
  } else if (upDownHeldOrRepeating || directionHeldNotRepeating) {
    event.preventDefault();
    return;
  } else if (directionKeySet.has(event.keyCode)) {
    handleBeforeArrow(event, editor, curLeafContainer as HTMLElement);
  }
  // Select next element
  else if (event.key === 'Tab') {
    handleTab(event, selectionElement?.closest('.field-input'), editor);
    return;
  }
  //keep track of current node and which keys are currently down
  let target = null;
  if (editor.selection) {
    target = Editor.edges(editor, editor.selection)?.[0];
  }
  const downKeys = keyState.downKeys;
  downKeys.add(event.keyCode);
  setKeyState({ target, downKeys });
};

export const handleTab = (event: KeyboardEvent, curField: any, editor: any) => {
  if (event.key !== 'Tab') {
    return;
  }

  event.preventDefault();

  const { shiftKey } = event;
  //get all input fields (<input> and inputElements) that can be edited
  const allFields = [
    ...document.querySelectorAll('.field-input:not(.uneditable)')
  ];
  let fieldIdx = allFields.findIndex((field) => {
    return field === curField;
  });

  // In case field is not found, reset selection to first element
  if (fieldIdx === -1) {
    fieldIdx = 0;
  }

  const lastFieldIdx = allFields.length - 1;
  //if shift, go backward else go forward
  fieldIdx = shiftKey ? fieldIdx - 1 : fieldIdx + 1;

  //if you went back from first, loop to last
  if (fieldIdx < 0) {
    fieldIdx = lastFieldIdx;
  }

  //if you went past last, loop back to first
  if (fieldIdx > lastFieldIdx) {
    fieldIdx = 0;
  }

  const field = allFields[fieldIdx] as HTMLElement;
  const isSlateNode = field.classList.contains('leafContainer');

  setTimeout(() => {
    field.focus();
    //get its path and select it
    if (isSlateNode) {
      const pathStr = (field.getAttribute('data-path') || '').split(',');
      const path = pathStr.map((s) => Number.parseInt(s));
      Transforms.select(editor, path as unknown as Location);
    }
    //select normally
    else {
      if (fieldIdx === 0) {
        Transforms.deselect(editor);
      }
      (field as HTMLInputElement).select();
    }
  }, 0);
};
