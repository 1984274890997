import React from 'react';
import { ForceRenderContext } from '../../context';
import { getChildrenChunks } from '../../helpers';
import {
  Component,
  Container,
  Dummy,
  ImageElement,
  InputElement
} from './field-elements';

const NodeRenderer = (props: any) => {
  const { forceRender } = React.useContext(ForceRenderContext) as any;
  props.forceRender = forceRender;
  props.node = props.element; //to avoid usage of element since it is reserved for other uses
  const childrenChunks = getChildrenChunks(props.node.children, props.children);

  switch (props.node.type) {
    case 'element':
      //if node has children that are more than text nodes, render as container instead
      if (childrenChunks.filter((c) => !c.textNodes!).length > 0) {
        return <Container {...props} childrenChunks={childrenChunks} />;
      }
      if (props.node.htmlTag === 'img') {
        return <ImageElement {...props} />;
      }
      return <InputElement {...props} />;

    case 'component':
      return <Component {...props} />;

    case 'container':
      if (props.node.isDummy) {
        return <Dummy {...props} />;
      }
      return <Container {...props} childrenChunks={childrenChunks} />;

    // 'text'
    default:
      return <></>;
  }
};

export default NodeRenderer;
