import React, { useState } from 'react';
import { Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor, useSlateStatic } from 'slate-react';
import { layersHoveredNodeClass } from '../..';
import AddNodeDividerWrapper from '../../../components/addNodeDivider/addNodeDividerWrapper';
import AddRemoveNodesBtns from '../../../components/addRemoveNodesBtns';
import Draggable from '../../../components/draggable';
import {
  asHistoryEditor,
  getDisplayName,
  getParent,
  isAncestorPlaceholder
} from '../../../helpers';
import { getHighlightClass } from '../../../utils';
import { useElementHoverListeners } from '../partials';
import ElementHeader from './element-header';
import TextElement from './text-element';

const classes = {
  root: 'bg-white dark:bg-black border border-primaryGrey border-l-[5px] border-l-primaryGrey my-[0px] mx-[5px] [&_.containerHeader]:m-0 [&_.contents.collapsed]:hidden',
  headerContainer:
    '[&.collapsibe:hover]:cursor-pointer m-0 py-[10px] px-[5px] flex flex-row gap=[15px] items-center justify-between [&:not(.hovered)]:bg-primaryGrey text-white',
  placeholder:
    'border border-primaryGrey/50 border-l-[5px] border-l-primaryGrey/50 [&_>_div_>_div:not(.node-btns):not(&_>_.header)]:opacity-50 [&_>_div_>_.header]:bg-disabledGrey/50 [&_>_div_>_.header_>_h4]:opacity-100 [&_>_div_>_.header_>_h4]:dark:opacity-50'
};

export const Container = (props: any) => {
  const {
    attributes,
    children,
    childrenChunks,
    element,
    darkClass,
    state,
    setState
  } = props;
  const {
    attributes: htmlAttributes,
    htmlTag = 'container',
    config = {}
  } = element;

  const { className = '' } = htmlAttributes;
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const { onMouseEnter, onMouseLeave } = useElementHoverListeners(
    editor,
    element,
    false
  );
  const { name } = config;
  const index = (path.at(-1) || 0) + 1;
  const containerNamePfx = className ? `${htmlTag}.${className}` : htmlTag;
  const containerName = `${containerNamePfx} - ${index}`;
  const highlightClass = getHighlightClass(element);
  const classNames = [classes.root, 'element-container', highlightClass];
  const uneditable =
    !!element.placeholder || isAncestorPlaceholder(editor, element);
  if (uneditable) classNames.push(classes.placeholder);
  const displayName = getDisplayName(editor, element, path, true);
  const [collapsed, setCollapsed] = useState(element.collapsed || false);
  const collapsedClass = collapsed ? 'collapsed' : '';
  const headerClassNames = [classes.headerContainer, 'element-header'];
  if (collapsed && highlightClass.includes(layersHoveredNodeClass)) {
    headerClassNames.push('hovered');
  }
  const parent = getParent(editor, path) as any;
  const collapsible = !!parent && parent.children.length > 1;
  if (collapsible) {
    headerClassNames.push('collapsible');
  }
  return (
    <AddNodeDividerWrapper element={element} path={path}>
      <Draggable
        className={classNames.join(' ')}
        element={element}
        path={path}
        name={name}
      >
        <div
          {...attributes}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            contentEditable={false}
            className={headerClassNames.join(' ')}
            onClick={(e) => {
              const target = e.target as any;
              //ignore when the icons are hit
              if (collapsible && target.tagName !== 'IMG') {
                setCollapsed(!collapsed);
                HistoryEditor.withoutSaving(asHistoryEditor(editor), () => {
                  Transforms.setNodes(
                    editor,
                    { collapsed: !collapsed },
                    { at: path }
                  );
                });
              }
            }}
          >
            <ElementHeader name={displayName || containerName} />
            <AddRemoveNodesBtns
              editor={editor}
              element={element}
              state={state}
              setter={setState}
            />
          </div>
          <div
            className={`contents ${collapsedClass}`}
            contentEditable={!uneditable}
            suppressContentEditableWarning
          >
            {!childrenChunks && children}
            {!!childrenChunks &&
              childrenChunks.map((chunk: any, i: any) => {
                //if not text nodes, return normally
                if (!chunk.textNodes) {
                  return (
                    <React.Fragment key={i}>{chunk.reactValues}</React.Fragment>
                  );
                }
                // render text nodes as special element similar to inputElement
                const textName = `${name} Text`;
                return (
                  <TextElement
                    key={i}
                    name={textName}
                    classes={classes}
                    rte={true}
                    darkClass={darkClass}
                    containerNode={element}
                    textNode={chunk.slateValues[0]}
                  >
                    {chunk.reactValues}
                  </TextElement>
                );
              })}
          </div>
        </div>
      </Draggable>
    </AddNodeDividerWrapper>
  );
};

export default Container;
