import { Node, Path, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';
import uniqid from 'uniqid';
import { PlaceholderNodeContextSetterType } from '../context';
import { toast } from '../shadcn/components/toast/use-toast';
import {
  LayersEditor,
  LayersGenericNode,
  LayersNode,
  LayersTreeNode,
  LayersTypelessNode,
  PlaceholderNodeMap
} from '../slatejs';

//determine if 2 (different) nodes are leaves in the same container
export function areSiblingLeaves(path1: Path, path2: Path) {
  if (path1.length !== path2.length) return false;
  for (let i = 0; i <= path1.length - 1; i++) {
    //if nodes aren't the same before the last node return false
    //otherwise return true if you made it to the last node and they don't equal each other
    if (path1[i] !== path2[i]) return i === path1.length - 1;
  }
  //return false if the paths are exactly the same
  return false;
}

//get the nodes that are to the right and left of "node" at "path"
export function getLeftAndRightSiblings(
  editor: LayersEditor,
  node: LayersTreeNode,
  path: Path
) {
  const children = Node.parent(editor, path).children;
  const nodeIdx = children.indexOf(node);
  return [children[nodeIdx - 1], children[nodeIdx + 1]];
}

export function getAdjacentTextSiblings(editor: ReactEditor, node: Node) {
  const path = getPath(editor, node as any);
  const parent = getParent(editor, path as any);
  if (!parent) return [];
  const siblings = parent.children || [];
  let hasNode = false;
  let chunk = [];
  for (const sibling of siblings) {
    if (!Object.hasOwn(sibling, 'text')) {
      if (hasNode) {
        return chunk;
      }
      chunk = [];
    } else {
      chunk.push(sibling);
      if (node === sibling) {
        hasNode = true;
      }
    }
  }
  if (!hasNode) return [];
  return chunk;
}

//checks if 2 nodes have the all the same mark styling (bold, sup, etc)
export function matchFormats(node1: LayersTreeNode, node2: LayersTreeNode) {
  if (!node1 || !node2) return false;
  const formats = ['strong', 'sup', 'bold'];
  for (const f of formats) {
    const bothHaveIt = !!node1[f] && !!node2[f];
    const neitherHaveIt = !node1[f] && !node2[f];
    if (!bothHaveIt && !neitherHaveIt) return false;
  }
  return true;
}

//get the path of the adjacent node, either after (default) or before the node at path
export function getAdjPath(path: Path = [], after: boolean = true): Path {
  const lastNode = (path.at(-1) || 0) - (after ? -1 : 1);
  return [...path.slice(0, -1), lastNode];
}

//after applying formatting from the toolbar, merge adjacent text nodes with the same formatting
export function mergeSiblings(
  editor: LayersEditor,
  siblings: LayersTreeNode[],
  mainPath: Path
) {
  const [left, main, right] = siblings;
  try {
    //mergeNode merges node at "at" with the node to the left
    if (matchFormats(main, right)) {
      Transforms.mergeNodes(editor, {
        //path of the node to the right
        at: getAdjPath(mainPath, true)
      });
    }
    if (matchFormats(main, left)) {
      Transforms.mergeNodes(editor, { at: mainPath });
    }
  } catch (error) {
    console.log(error);
  }
}

//determines if node is a descendant of a placeholder node
export const isAncestorPlaceholder = (
  editor: LayersEditor,
  node: LayersNode
): boolean => {
  try {
    const path = ReactEditor.findPath(editor, node);
    const parent = Node.parent(editor, path) as LayersNode;
    if ((parent as any).placeholder) {
      return true;
    }
    return isAncestorPlaceholder(editor, parent);
  } catch (error) {
    return false;
  }
};

/**
 * find which children are text nodes and group adjacent ones together
 * return list of the react children and slate children grouped
 */
export const getChildrenChunks = (nodeChildren: any, reactChildren: any) => {
  let res = [];
  let chunk = { textNodes: true, reactValues: [], slateValues: [] };
  for (let i = 0; i < nodeChildren.length; i++) {
    const nodeChild = nodeChildren[i];
    const reactChild = reactChildren[i];
    //not a text node
    if (!Object.hasOwn(nodeChild, 'text')) {
      //empty out chunk of text nodes
      if (chunk.reactValues.length > 0) {
        res.push(chunk);
        chunk = { textNodes: true, reactValues: [], slateValues: [] };
      }
      //add node
      res.push({
        textNodes: false,
        reactValues: [reactChild],
        slateValues: [nodeChild]
      });
      //add text node to chunk
    } else {
      chunk.reactValues.push(reactChild as never);
      chunk.slateValues.push(nodeChild as never);
    }
  }
  if (chunk.reactValues.length > 0) {
    res.push(chunk);
  }
  return res;
};
/* get the full path of a node
 * ex: T3 Component 1/1 -> T3 Table 5/5 -> T3 Cell 1/4 -> T3 Image 1/4
 */
export const getNodePathStr: any = (
  editor: LayersEditor,
  node: LayersTreeNode,
  tokens: Array<string>
) => {
  if (!node?.config?.name) return joinTokensInReverse(tokens);
  // Leave tokens array intact
  const modifiedTokens = [
    ...tokens,
    `${node.config.name} ${getNodeIndexStr(editor, node)}`
  ];
  const path = getPath(editor, node);
  if (!path) return joinTokensInReverse(modifiedTokens);
  const parent = getParent(editor, path);
  if (!parent) return joinTokensInReverse(modifiedTokens);
  return getNodePathStr(editor, parent, modifiedTokens);
};

// Join path tokens in reverse order
const joinTokensInReverse = (arr: Array<string>): string =>
  arr.reverse().join(' -> ');

//remove subsequent adjacent text nodes
// if you have {text:"15"} and then {text:"ppm", bold:true}, just keep {text:"15"}
const removeSubseqAdjText = (children: LayersNode[]): LayersNode[] => {
  const newChildren: LayersNode[] = [];
  let prevIsText = false;
  for (const child of children) {
    const isText = Object.hasOwn(child, 'text');
    if (isText && prevIsText) {
      continue;
    } else {
      prevIsText = isText;
      newChildren.push(child);
    }
  }
  return newChildren;
};

/**
 * find out which index "node" is relative to its siblings
 * return string showing its index out of its same-typed siblings
 * if getParent is true, return the same thing for its parent node first
 */
export const getNodeIndexStr = (
  editor: LayersEditor,
  node: LayersTreeNode
): string => {
  const path = getPath(editor, node);
  if (!path) return '';
  const parent = getParent(editor, path);
  if (!parent) return '';
  const siblings = removeSubseqAdjText(parent.children || []);
  const index = siblings.indexOf(node) + 1;
  //hide "1/1"
  if (!index || (index === 1 && siblings.length === 1)) return '';
  return `${index}/${siblings.length}`.trim();
};

export function getParent(editor: LayersEditor, path: Path): Node | null {
  try {
    return Node.parent(editor, path);
  } catch (error) {
    return null;
  }
}

export function getNode(editor: LayersEditor, path: Path): Node | null {
  try {
    return Node.get(editor, path);
  } catch (error) {
    return null;
  }
}

export function getPath(editor: LayersEditor, node: LayersTreeNode) {
  try {
    return ReactEditor.findPath(editor, node);
  } catch (error) {
    return null;
  }
}

export function getPrevPath(path: Path): Path {
  try {
    return Path.previous(path);
  } catch (e) {
    return [];
  }
}

export function getNextPath(path: Path): Path {
  try {
    return Path.next(path);
  } catch (e) {
    return [];
  }
}

//if two children have the same config, only keep one of them
//returns the length of the resulting list
export function getUniqueCurrentChildren(children: any) {
  const newChildren = [];
  if (children.length > 0) newChildren.push(children[0]);
  for (let i = 1; i < children.length; i++) {
    const curChild = children[i];
    const prevChild = children[i - 1];
    if (Object.hasOwn(curChild, 'text')) {
      newChildren.push(curChild);
    } else if (curChild?.config?.id !== prevChild?.config?.id) {
      newChildren.push(curChild);
    }
  }
  return newChildren;
}

//quickly cast layersEditor to historyEditor without intermediate cast
export const asHistoryEditor = (editor: LayersEditor): HistoryEditor =>
  editor as unknown as HistoryEditor;

export const moveNodes = (
  editor: LayersEditor,
  dragPath: Path,
  dropPath: Path
) => {
  try {
    Transforms.moveNodes(editor, {
      at: dragPath,
      to: dropPath
    });
    setTimeout(() => toggleOff(editor, dragPath, 'hovered'), 1);
    setTimeout(() => toggleOff(editor, dropPath, 'hovered'), 1);
  } catch (e) {}
};

export const toggleOn = (
  editor: LayersEditor,
  path: Path,
  property: string
) => {
  HistoryEditor.withoutSaving(asHistoryEditor(editor), () => {
    Transforms.setNodes(
      editor,
      // Undefined to remove
      { [property]: true },
      { at: path }
    );
  });
};

export const toggleOff = (
  editor: LayersEditor,
  path: Path,
  property: string
) => {
  HistoryEditor.withoutSaving(asHistoryEditor(editor), () => {
    Transforms.setNodes(
      editor,
      // Undefined to remove
      { [property]: undefined },
      { at: path }
    );
  });
};

//cleans up node name to display
//remove component and parent prefixes, only show its idx out of same type siblings
export function getDisplayName(
  editor: LayersEditor,
  node: LayersTreeNode,
  path: Path,
  showIdx?: boolean
) {
  let nodeName = node?.config?.name || '';
  const parent = getParent(editor, path) as any;
  const parentName = parent?.config?.name || '';
  const parentNameRegex = new RegExp(parentName, 'gi');
  nodeName = nodeName.replace(parentNameRegex, '').trim();
  const component = getNode(editor, [0]) as any;
  const componentName = (component?.config?.name || '').split(' ')[0] || '';
  nodeName = nodeName.replace(componentName, '').trim();
  const sameTypeSiblings = parent.children.filter(
    (child: any) => child?.config?.name === node?.config?.name
  );
  const idx = sameTypeSiblings.indexOf(node) + 1;
  if (showIdx) {
    nodeName = `${nodeName} ${idx}`;
  }
  return nodeName || '';
}

export const getSameTypeSiblings = (
  editor: LayersEditor,
  node: LayersTreeNode
) => {
  const parent = getParent(editor, getPath(editor, node) as any);
  if (!parent || !parent.children) {
    return [];
  }
  return parent.children.filter(
    (child) => child?.config?.name === node?.config?.name
  );
};
/**
 * get node and its surrounding siblings of same type
 */
export const getSameTypeSiblingCluster = (editor: ReactEditor, node: Node) => {
  const path = getPath(editor, node as any);
  if (!path) return [];
  const parent = getParent(editor, path);
  if (!parent) return [];
  const siblings = parent.children || [];
  const nodeType = (node as any)?.config?.name;
  let hasNode = false;
  let chunk = [];
  for (const sibling of siblings) {
    const siblingType = sibling?.config?.name;
    if (nodeType !== siblingType) {
      if (hasNode) {
        return chunk;
      }
      chunk = [];
    } else {
      chunk.push(sibling);
      if (node === sibling) {
        hasNode = true;
      }
    }
  }
  if (!hasNode) return [];
  return chunk;
};

/**
 * duplicate a node with a new id and with/without its text
 */
export const cloneNode = (
  editor: LayersEditor,
  node: LayersTypelessNode,
  withoutText: boolean
): LayersTypelessNode => {
  const { children, attributes, ...other } = node;
  const newChildren = [];
  for (const child of node.children || []) {
    if (Object.hasOwn(child, 'text')) {
      // exclude formats
      const { strong, bold, sup, ...other } = child;
      const text = withoutText ? '' : child.text;
      newChildren.push({ ...other, text });
    } else newChildren.push(cloneNode(editor, child, withoutText));
  }
  const editablePropsSet = new Set([
    ...(node?.config?.editableProps || []).map((p: any) => p.value)
  ]);
  if (node.htmlTag === 'img') {
    editablePropsSet.add('src');
    editablePropsSet.add('alt');
  }
  const newAttributes = Object.entries(attributes || {}).reduce(
    (acc: any, [k, v]) => {
      if (editablePropsSet.has(k) && withoutText) {
        acc[k] = '';
      } else {
        acc[k] = v;
      }
      return acc;
    },
    {}
  );
  const clone = {
    ...other,
    children: newChildren,
    attributes: newAttributes
  };
  if (Object.hasOwn(node, 'unique_id')) {
    (clone as any).unique_id = uniqid(`${clone.htmlTag}-`);
  }

  return clone;
};

export const createNodeHelper = (
  editor: LayersEditor,
  node: LayersGenericNode,
  path: Path,
  emptyText: boolean,
  placeholderNodes: PlaceholderNodeMap,
  setPlaceholderNodes: PlaceholderNodeContextSetterType
): void => {
  const siblings = getSameTypeSiblingCluster(editor, node as any) as any;
  const noLiveNodes = siblings.length === 1 && !!siblings[0].placeholder;
  if (noLiveNodes || !!(node as any).placeholder) {
    //if the only node left is a placeholder one, just revive it instead of inserting a new one
    Transforms.removeNodes(editor, { at: path });
    Transforms.insertNodes(editor, { ...node, placeholder: false } as any, {
      at: path
    });
    const newPlaceholderNodes = Object.entries(placeholderNodes).reduce(
      (acc: any, [k, v]) => {
        if (JSON.stringify(path) !== k) acc[k] = v;
        return acc;
      },
      {}
    );
    setPlaceholderNodes(newPlaceholderNodes);
  } else {
    //create new node based on the one that was added
    const { unique_id, ...other } = node;
    const newNode = cloneNode(editor, other, emptyText);
    newNode.unique_id = uniqid(`${newNode.htmlTag}-`);
    Transforms.insertNodes(editor, newNode as any, {
      at: getAdjPath(path, true)
    });
  }
};

const showToast = (
  type: string = '',
  title: string = '',
  description: string = '',
  duration: number = 10000,
  action: any = null
) => {
  const classMap: any = {
    default: 'text-black dark:text-white bg-white dark:!bg-black',
    success: '!bg-green-600 text-white',
    warning: '!bg-yellow-600 text-white',
    error: '!bg-red-600 text-white'
  };
  const { dismiss } = toast({
    title,
    description,
    className: classMap[type || ''] || '',
    action
  });
  setTimeout(() => {
    dismiss();
  }, duration);
};

export const showToastDefault = (
  title?: string,
  message?: string,
  duration?: number,
  action?: any
) => showToast('default', title, message, duration, action);

export const showToastSuccess = (message: string, duration?: number) =>
  showToast('success', 'Success', message, duration, null);

export const showToastWarning = (message: string, duration?: number) =>
  showToast('warning', 'Warning', message, duration, null);

export const showToastError = (
  message: string,
  duration?: number,
  action?: any
) => showToast('error', 'Error', message, duration, action);
