import { ReactEditor, useSlateStatic } from 'slate-react';

import EditableElementAttribute from './editable-attribute';
import ElementHeader from './element-header';

import { LayersTypelessNode } from '../../types';

import { getHighlightClass } from '../../../utils';

import AddNodeDividerWrapper from '../../../components/addNodeDivider/addNodeDividerWrapper';
import AddRemoveNodesBtns from '../../../components/addRemoveNodesBtns';
import Draggable from '../../../components/draggable';
import { getDisplayName, isAncestorPlaceholder } from '../../../helpers';
import { useElementHoverListeners } from '../partials';

type InputProps = {
  element: LayersTypelessNode;
  children: any;
  attributes: any;
  classes: any;
};

const classes = {
  root: 'bg-white dark:bg-black p-[10px] mt-0 mr-[5px] mb-0 ml-[10px] border border-primaryGrey border-l-[5px] border-l-primaryGrey',
  leafContainer:
    'border border-[#333333] dark:border-white w-[calc(100%-15px]) p-[5px] bg-white min-h-[15px]',
  rte: 'border border-[#333333] dark:border-white min-h-[30px] resize-y overflow-auto',
  elementAttribute:
    '[&_h5]:mt-[5px] [&_input]:w-[calc(100%-15px)] [&_input]:min-h-[15px] [&_input]:p-[5px] [&_input]:border [&_input]:border-[#333333] [&_input]:dark:border-white',
  headerContainer:
    'flex flex-row items-center justify-between m-0 pb-[5px] gap-[15px]',
  placeholder:
    'border border-primaryGrey/50 border-l-[5px] border-l-primaryGrey/50 [&_>_div_>_div:not(.node-btns):not(&_>_.header)]:opacity-50 [&_>_div_>_.header_>_h4]:opacity-50'
};

const InputElement = (props: any) => {
  const { element, children, attributes }: InputProps = props;
  const { config } = element;
  const editor = useSlateStatic();
  const uneditable =
    !!element.placeholder || isAncestorPlaceholder(editor, element as any);
  const highlightClass = getHighlightClass(element);
  const classNames = [
    classes.root,
    'inputElement',
    !uneditable ? highlightClass : '',
    uneditable ? classes.placeholder : ''
  ];
  const {
    editableProps = [],
    supportsChildren = true,
    constraints = {}
  } = config || {};
  const { rte = false } = constraints as any;
  const editablePropValueSet = new Set();
  const editablePropsByValue = {};
  (editableProps || []).forEach((editableProp) => {
    editablePropValueSet.add(editableProp.value);
    (editablePropsByValue as any)[editableProp.value] = editableProp.label;
  });
  //find the element's attributes that should be editable as specified by the config
  const editableAttributes = [];
  for (const k of Object.keys(element.attributes || {})) {
    if (editablePropValueSet.has(k))
      editableAttributes.push({
        attrKey: k,
        attrLabel: (editablePropsByValue as any)[k]
      });
  }
  if (editableAttributes.length > 0) {
    classNames.push(classes.elementAttribute);
  }
  const path = ReactEditor.findPath(editor, element as any);
  const { onMouseEnter, onMouseLeave } = useElementHoverListeners(
    editor,
    element,
    false
  );

  const leafContainerClassNames = [
    classes.leafContainer,
    'leafContainer',
    'field-input',
    uneditable ? 'uneditable' : '',
    !!rte ? classes.rte : '',
    !!rte ? 'rte' : ''
  ].join(' ');
  const displayName = getDisplayName(editor, element as any, path);
  const name = element?.config?.name;
  const headerClassNames = [classes.headerContainer, 'element-header'].join(
    ' '
  );
  return (
    <AddNodeDividerWrapper element={element} path={path}>
      <Draggable
        className={classNames.join(' ')}
        element={element}
        path={path}
        name={name}
      >
        <div
          data-path={path}
          {...attributes}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div className={headerClassNames} contentEditable={false}>
            <ElementHeader name={displayName || element.htmlTag} />
            <AddRemoveNodesBtns editor={editor} element={element} />
          </div>
          {supportsChildren && (
            <div
              contentEditable={!uneditable}
              suppressContentEditableWarning={true}
              data-path={path}
              tabIndex={0}
              className={leafContainerClassNames}
            >
              {children}
            </div>
          )}
          {editableAttributes.map((a, i) => (
            <EditableElementAttribute
              key={i}
              {...{ element, attrKey: a.attrKey, attrLabel: a.attrLabel, path }}
            />
          ))}
        </div>
      </Draggable>
    </AddNodeDividerWrapper>
  );
};

export default InputElement;
