import { useEffect, useRef, useState } from 'react';
import { Editor, Path, Range, Text, Transforms } from 'slate';
import { useFocused, useSlate } from 'slate-react';
import {
  areSiblingLeaves,
  getLeftAndRightSiblings,
  mergeSiblings
} from '../../helpers';
import { LayersEditor, LayersElementNode } from '../../slatejs';
import { Button, Portal } from './components';

const classes = {
  toolbar:
    'absolute mt-[-40px] bg-gray-900 flex p-[10px] gap-[15px] [&.display-false]:hidden'
};

const toggleFormat = (
  editor: LayersEditor,
  format: string,
  shiftHeld: boolean
) => {
  if (format === 'bold' && shiftHeld) format = 'strong';
  //if the selection has that format, return the path of that node
  let [activeNode, activePath] = activeFormatPath(editor, format) || [];
  //if you get a path, the format is currently active
  const isActive = !!activePath;
  const formatObj = { [format]: isActive ? null : true };
  if (format === 'bold' && isActive) (formatObj as any)['strong'] = null;
  if (format === 'strong' && isActive) (formatObj as any)['bold'] = null;
  Transforms.setNodes(editor, formatObj, { match: Text.isText, split: true });
  //if the format has just been applied, activeNode and activePath will exist
  [activeNode, activePath] = activeFormatPath(editor, format) || [];
  if (!!activeNode && !!activePath) {
    //mergeNodes that have the same formatting
    const [left, right] = getLeftAndRightSiblings(
      editor,
      activeNode as LayersElementNode,
      activePath
    );
    mergeSiblings(
      editor,
      [
        left as LayersElementNode,
        activeNode as LayersElementNode,
        right as LayersElementNode
      ],
      activePath
    );
  } else {
    //if a format has been taken away, use the selection to get the right node
    //ex: you took away sup from a bold node with bold neighbors. Merge the 3 bold nodes
    const [selectedNode, selectedPath] =
      editor.selection && (Editor.node(editor, editor.selection.focus) as any);
    const [left, right] = getLeftAndRightSiblings(
      editor,
      selectedNode,
      selectedPath
    );
    mergeSiblings(editor, [left, selectedNode, right], selectedPath);
  }
  //now look at the nodes to the left and right of it. If they exist and have the same format, merge
  // const sibling = document.create()
};
//return the node and path within the selection that matches the format
const activeFormatPath = (editor: LayersEditor, format: string) => {
  let secondFormat = format;
  if (format === 'bold') secondFormat = 'strong';
  if (format === 'strong') secondFormat = 'bold';
  let [match] = Editor.nodes(editor, {
    match: (n) =>
      (n[format as keyof typeof n] as unknown) === true ||
      (n[secondFormat as keyof typeof n] as unknown) === true,
    mode: 'all'
  });
  return match;
};
const FormatButton = (props: any) => {
  const { format } = props;
  const editor = useSlate();
  return (
    <Button
      title={format === 'bold' ? 'Hold shift to use strong' : null}
      reversed
      active={!!activeFormatPath(editor, format)}
      onClick={(e: any) => toggleFormat(editor, format, e['shiftKey'])}
    >
      {format}
    </Button>
  );
};

export const HoveringToolbar = (props: any) => {
  const { editorRef } = props;
  const ref = useRef<HTMLDivElement>(null);
  const editor = useSlate();
  const inFocus = useFocused();
  const [showToolbar, setShowToolbar] = useState(false);
  const [scrollIng, setScrolling] = useState(false);

  useEffect(() => {
    //hide if the selection is not in the toolbar, or if the user clicks outside relevant nodes
    const handleClick = (e: any) => {
      const selection = window.getSelection()?.toString() || '';
      const target = e.target as HTMLElement;
      const insideEditor = !!target.closest('.editor-modular');
      const insideToolBar = !!target.closest('.toolbar');
      const insideAcceptableTarget = insideEditor || insideToolBar;
      if (!selection || !insideAcceptableTarget) {
        setShowToolbar(false);
      }
    };
    let windowTimer: any = null;
    const editorRefCurrent = editorRef.current;
    const handleScroll = (e: any) => {
      setScrolling(true);
      if (!!windowTimer) {
        clearTimeout(windowTimer);
      }
      windowTimer = setTimeout(() => {
        setScrolling(false);
      }, 200);
    };
    document.addEventListener('click', handleClick);
    if (!!editorRefCurrent)
      editorRefCurrent.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('click', handleClick);
      if (!!editorRefCurrent)
        editorRefCurrent.removeEventListener('scroll', handleScroll);
    };
    //eslint-disable-next-line
  }, []);
  //eslint-disable-next-line
  useEffect(() => {
    const el = ref.current;
    const { selection } = editor;

    if (!el) {
      return;
    }

    const { anchor, focus } = selection || {};
    const startPath = anchor?.path || [];
    const endPath = focus?.path || [];
    //don't allow toolbar if selection goes into different node containers
    //or if rte isn't enabled for that node
    const sameContainer =
      Path.equals(startPath, endPath) || areSiblingLeaves(startPath, endPath);
    const [selectedNodeInfo] = Editor.node(editor, startPath) || [];
    const selectedNodeIsRte = (selectedNodeInfo as any)?.parentConfig
      ?.constraints?.rte;
    if (
      !selection ||
      !inFocus ||
      Range.isCollapsed(selection) ||
      Editor.string(editor, selection) === '' ||
      !sameContainer ||
      !selectedNodeIsRte
    ) {
      el.removeAttribute('style');
      setShowToolbar(false);
    } else {
      try {
        //set positioning around where the selection is
        const domSelection = window.getSelection();
        const domRange = domSelection?.getRangeAt(0);
        const rect = domRange?.getBoundingClientRect();
        el.style.opacity = '1';
        el.style.top = `${
          rect?.top || 0 + window.pageYOffset - el.offsetHeight
        }px`;
        el.style.left = `${
          rect?.left ||
          0 + window.pageXOffset - el.offsetWidth / 2 + (rect?.width || 0) / 2
        }px`;
        setShowToolbar(true);
      } catch (error) {
        console.log(error);
      }
    }
    //eslint-disable-next-line
  });
  return (
    <Portal>
      <div
        className={`${classes.toolbar} toolbar display-${
          showToolbar && !scrollIng
        }`}
        ref={ref}
        onMouseDown={(e: any) => {
          e.preventDefault();
        }}
      >
        <FormatButton format="bold" icon="format_bold" />
        {/* <FormatButton format="strong" icon="format_strong" /> */}
        {/* <FormatButton format="italic" icon="format_italic" /> */}
        {/* <FormatButton format="underlined" icon="format_underlined" /> */}
        <FormatButton format="sup" icon="format_sup" />
      </div>
    </Portal>
  );
};
