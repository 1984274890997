import React from 'react';
import { Node, Path, Transforms } from 'slate';
import { ReactEditor } from 'slate-react';
import {
  PlaceholderNodeContext,
  PlaceholderNodeContextSetterType
} from '../context';
import {
  cloneNode,
  createNodeHelper,
  getParent,
  getSameTypeSiblingCluster,
  isAncestorPlaceholder
} from '../helpers';
import { LayersEditor, LayersNode, PlaceholderNodeMap } from '../slatejs';
import { AddNodeBtn, CopyNodeBtn, RemoveNodeBtn } from './icons';

//determine if all siblings of any type are placeholders
const allPlaceholderSiblings = (
  editor: LayersEditor,
  node: Node
): boolean => {
  const path = ReactEditor.findPath(editor, node);
  const parent = getParent(editor, path);
  if (!parent) return false;
  const siblings = parent.children || [];
  for (const sibling of siblings) {
    if (sibling !== node && !(sibling as any).placeholder) {
      return false;
    }
  }
  return true;
};

const removeNode = (
  editor: LayersEditor,
  node: LayersNode,
  path: Path,
  placeholderNodes: PlaceholderNodeMap,
  setPlaceholderNodes: PlaceholderNodeContextSetterType
): void => {
  //if removing this node would remove all children in its parent, remove the parent instead
  if (allPlaceholderSiblings(editor, node)) {
    try {
      const parent = Node.parent(editor, path);
      return removeNode(
        editor,
        parent,
        ReactEditor.findPath(editor, parent),
        placeholderNodes,
        setPlaceholderNodes
      );
    } catch (e) {}
  }
  const siblings = getSameTypeSiblingCluster(editor, node) as any;
  const isLastOne = siblings.length === 1 && !node?.config?.constraints?.min;
  Transforms.removeNodes(editor, { at: path });
  //if it's the last of its kind, add a placeholder empty version
  if (isLastOne) {
    const newPlaceholderNodes = addPlaceholderNode(
      editor,
      node,
      path,
      placeholderNodes
    );
    // Update placeholders with added one
    setPlaceholderNodes(newPlaceholderNodes);
  }
  //breifly set selection to an existing node to avoid domSelection error
  Transforms.select(editor, ReactEditor.findPath(editor, siblings[0]));
  Transforms.deselect(editor);
};

/**
 * Add empty placeholder at a given path
 * @param node node to make an empty copy of
 * @param path Where to insert the node
 * @param placeholderNodes Existing placeholder nodes
 * @returns The updated placeholder map
 */
const addPlaceholderNode = (
  editor: LayersEditor,
  node: LayersNode,
  path: Path,
  placeholderNodes: PlaceholderNodeMap
): PlaceholderNodeMap => {
  //set its placeholder property to be ignored by the serializer
  const emptyClone = cloneNode(editor, node, true);
  Transforms.insertNodes(
    editor,
    { ...(emptyClone as Node), placeholder: true },
    { at: path }
  );
  //keep track of this placeholder node
  return {
    ...placeholderNodes,
    [JSON.stringify(path)]: { ...emptyClone, placeholder: true }
  } as any;
};

const classes = {
  container: 'flex gap-2',
  figure:
    'm-0 flex justify-center items-center w-5 h-5 bg-white rounded-full shadow-roundedBlack [&.disabled]:opacity-50 [&.disabled]:hover:cursor-not-allowed hover:cursor-pointer [&_img]:w-3.5 [&_img]:h-3.5'
};

export default function AddRemoveNodesBtns(props: any) {
  const { placeholderNodes, setPlaceholderNodes } = React.useContext(
    PlaceholderNodeContext
  );
  const { editor, element: node } = props;
  const adjSameTypeSiblings = getSameTypeSiblingCluster(editor, node) as any;
  //exclude nodes that are "placeholder" AKA ignored by the editor
  const nonPlaceholderSameTypeSiblings = adjSameTypeSiblings.filter(
    (s: any) => !s.placeholder
  );
  const min = node?.config?.constraints?.min || 0;
  const max = node?.config?.constraints?.max || 0;
  const noLimit = (min === 0 && max === 0) || max < min;
  const showAdd = nonPlaceholderSameTypeSiblings.length < max || noLimit;
  const showRemove = nonPlaceholderSameTypeSiblings.length > min;

  const path = ReactEditor.findPath(editor, node);
  const ancestorIsPlaceholder = isAncestorPlaceholder(editor, node);
  const isPlaceholderNode = !!node.placeholder || ancestorIsPlaceholder;

  const createNode = (emptyText: boolean) =>
    createNodeHelper(
      editor,
      node,
      path,
      emptyText,
      placeholderNodes,
      setPlaceholderNodes
    );

  const getDisabledClass = (condition: boolean) =>
    !!condition ? 'disabled' : '';

  const getBtnClasses = (extraClass?: string) =>
    `${classes.figure} ${extraClass}`;
  return (
    <div className={`${classes.container} node-btns`}>
      <figure
        className={getBtnClasses(
          getDisabledClass(!showAdd || isPlaceholderNode)
        )}
        onClick={(e) => {
          e.stopPropagation();
          if (!showAdd || isPlaceholderNode) {
            return;
          }
          createNode(false);
        }}
      >
        <CopyNodeBtn />
      </figure>
      {isPlaceholderNode && !ancestorIsPlaceholder && (
        <figure
          className={getBtnClasses()}
          onClick={(e) => {
            e.stopPropagation();
            createNode(true);
          }}
        >
          <AddNodeBtn />
        </figure>
      )}
      {(!isPlaceholderNode || ancestorIsPlaceholder) && (
        <figure
          className={getBtnClasses(
            getDisabledClass(!showRemove || isPlaceholderNode)
          )}
          onClick={(e) => {
            e.stopPropagation();
            if (!showRemove || isPlaceholderNode) {
              return;
            }
            removeNode(
              editor,
              node,
              path,
              placeholderNodes,
              setPlaceholderNodes
            );
          }}
        >
          <RemoveNodeBtn />
        </figure>
      )}
    </div>
  );
}
