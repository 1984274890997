import { Path } from 'slate';
import { LayersTypelessNode } from '../../types';
import useElementAttributeToggle from './use-attribute-toggle';

/**
 * Highlight focused element
 */
const useElementFocusListeners = (node: LayersTypelessNode, path?: Path) => {
  const { toggleOn, toggleOff } = useElementAttributeToggle(
    node,
    'focused',
    path
  );
  return {
    onFocus: toggleOn,
    onBlur: toggleOff
  };
};

export default useElementFocusListeners;
